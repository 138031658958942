import React from "react";
import { Typography } from "../../../ui";
import Offer from "../components/Offer";
import RequestHeader from "../components/RequestHeader";

const NoAcceptedConfirmationsYetView = ({ request }) => {
  const { hideLabels } = request;

  return (
    <>
      <RequestHeader request={request} />

      <div className="mt-5">
        <Typography.Text size="lead" grey>
          Kreditorių pasiūlymai
        </Typography.Text>
      </div>

      <div className="mt-1">
        {request.availableConfirmations
          .filter((creditCompany) => creditCompany.visible)
          .map((creditCompany, index) => (
            <div className="my-3" key={index}>
              <Offer creditCompany={creditCompany} hideLabel={hideLabels} />
            </div>
          ))}
      </div>
    </>
  );
};

export default NoAcceptedConfirmationsYetView;
