import React from "react";
import SidebarWrapper from "./components/SidebarWrapper";
import ContentWrapper from "./components/ContentWrapper";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { Visible } from "react-grid-system";

const DashboardLayout = ({
  title = "Placeholder title",
  children,
  activeRoute,
}) => (
  <div className="route-fade-in">
    <SidebarWrapper>
      <Sidebar activeRoute={activeRoute} />
    </SidebarWrapper>

    <ContentWrapper>
      <div class="py-4 px-2 px-sm-4">
        <Navbar title={title} activeRoute={activeRoute} />

        <Visible xs sm md lg>
          <div className="mt-4">{title}</div>
        </Visible>

        <div>{children}</div>
      </div>
    </ContentWrapper>
  </div>
);

export default DashboardLayout;
