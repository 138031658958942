import React from "react";
import styled, { css } from "styled-components";
import { Col, Row } from "react-grid-system";
import { CgClose } from "react-icons/cg";

const AlertWrapper = styled.div`
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px 20px;
  font-size: 14px;

  @media (max-width: 768px) {
    padding: 8px 20px;
  }

  ${({ success }) =>
    success &&
    css`
      background: #e7ffed;
      border: 1px solid #82e89f;
      color: #3bb05c;
    `}

  ${({ danger }) =>
    danger &&
    css`
      background: #fff4f4;
      border: 1px solid #f58f8f;
      color: #d02b2b;
    `}

  ${({ warning }) =>
    warning &&
    css`
      background: #fffce0;
      border: 1px solid #ffd600;
      color: #222222;
    `}
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 10px;
  margin: 0;
  color: inherit;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
`;

const Alert = (props) => {
  const [visible, setVisible] = React.useState(true);

  if (!visible) {
    return "";
  }

  return (
    <AlertWrapper {...props}>
      <Row gutterWidth={0}>
        <Col xs={10} className="my-auto text-justify">
          {props.children}
        </Col>
        <Col className="my-auto text-right">
          <CloseButton onClick={() => setVisible(false)}>
            <CgClose className="va-middle" />
          </CloseButton>
        </Col>
      </Row>
    </AlertWrapper>
  );
};

export default Alert;
