import styled from "styled-components";

const ContentWrapper = styled.main`
  background: #f8f8f8;
  position: relative;
  margin-left: 350px;
  min-height: 100vh;

  @media (max-width: 1199px) {
    margin-left: 0;
  }
`;

export default ContentWrapper;
