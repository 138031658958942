import React from "react";
import { Card, PaddingWrapper, Typography, Input, Button, Select, RadioInput, ButtonAdornment } from "../../../ui";
import { ErrorFocus, FileUpload, FormRow, RequestStepperHeader } from "../../../components";
import { Formik, Field, Form } from "formik";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import * as yup from "yup";
import errors from "../../../locale/lt_LT/errors";
import validatePersonalId from "../../../helpers/validatePersonalId";
import Cross from "../../../assets/img/cross.svg";
import { useState } from "react";
import { Row } from "react-grid-system";
import "../../RequestPage/components/offer.css";

const requestTranslations = require("../../../locale/lt_LT/request").default;

const validationSchema = yup.object().shape({
  firstName: yup.string(errors.invalidFormat).required(errors.required),
  lastName: yup.string(errors.invalidFormat).required(errors.required),
  personalID: yup
    .string(errors.invalidFormat)
    .matches(/^[0-9]+$/, errors.invalidFormat)
    .min(11, errors.invalidFormat)
    .max(11, errors.invalidFormat)
    .required(errors.required)
    .test("validate-personal-id", function (personalId) {
      const { path, createError } = this;
      let isValid = true;

      try {
        isValid = validatePersonalId(personalId);
      } catch {
        isValid = false;
      }

      return isValid ? true : createError({ path, message: errors.invalidFormat });
    }),
  educationLevel: yup.string(errors.invalidFormat).required(errors.required),
  occupationType: yup.string(errors.invalidFormat).required(errors.required),
  workExperience: yup.string(errors.invalidFormat).required(errors.required),
  personalIncome: yup.number(errors.invalidFormat).min(0, errors.invalidFormat).required(errors.required),
  financialObligations: yup.number(errors.invalidFormat).min(0, errors.invalidFormat).required(errors.required),
  housingObligations: yup.number(errors.invalidFormat).min(0, errors.invalidFormat).required(errors.required),
  futureFinancialSituationChange: yup.bool(errors.invalidFormat).required(errors.required),
  futurePersonalIncome: yup.number(errors.invalidFormat).when("futureFinancialSituationChange", {
    is: true,
    then: yup.number(errors.invalidFormat).min(0, errors.invalidFormat).required(errors.required),
  }),
  futureFinancialObligations: yup.number(errors.invalidFormat).when("futureFinancialSituationChange", {
    is: true,
    then: yup.number(errors.invalidFormat).min(0, errors.invalidFormat).required(errors.required),
  }),
  isRelatedToPolitics: yup.bool(errors.invalidFormat),
  isRelatedToPoliticsHimself: yup.bool(errors.invalidFormat),
  politicallyActivePersonFirstName: yup
    .string(errors.invalidFormat)
    .when(["isRelatedToPolitics", "isRelatedToPoliticsHimself"], {
      is: (isRelatedToPolitics, isRelatedToPoliticsHimself) => {
        return isRelatedToPolitics && !isRelatedToPoliticsHimself;
      },
      then: yup.string(errors.invalidFormat).required(errors.required),
    }),
  politicallyActivePersonLastName: yup
    .string(errors.invalidFormat)
    .when(["isRelatedToPolitics", "isRelatedToPoliticsHimself"], {
      is: (isRelatedToPolitics, isRelatedToPoliticsHimself) => {
        return isRelatedToPolitics && !isRelatedToPoliticsHimself;
      },
      then: yup.string(errors.invalidFormat).required(errors.required),
    }),
  politicallyActivePersonDuties: yup.string(errors.invalidFormat).when("isRelatedToPolitics", {
    is: true,
    then: yup.string(errors.invalidFormat).required(errors.required),
  }),
  ownsRealEstate: yup.bool(errors.invalidFormat),
  ownsCar: yup.bool(errors.invalidFormat),
  beneficialOwner: yup.bool(errors.invalidFormat),
  documentFilePaths: yup
    .array()
    .of(yup.object())
    .when("occupationType", {
      is: (occupationType) => {
        return ["working-abroad", "self-employed", "other"].includes(occupationType);
      },
      then: yup.array().of(yup.object()).required(errors.requiredDocuments),
    }),
});

const PersonalInfoStep1 = ({ values, setValues }) => {
  const [hide, setHide] = React.useState(false);
  const InfoSign = require(`../../../assets/img/infoSign.png`);

  const toggleHide = () => {
    setHide((oldState) => !oldState);
  };

  return (
    <>
      <RequestStepperHeader step={2} title="Asmeniniai duomenys" />

      <FormRow>
        <Formik
          initialValues={values}
          onSubmit={async (values) => {
            setValues(values);
          }}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, touched, errors, isValid }) => (
            <Form>
              <PaddingWrapper>
                <Field
                  label="Vardas"
                  id="firstName"
                  name="firstName"
                  type="text"
                  as={Input}
                  fullWidth
                  error={errors.firstName && touched.firstName}
                  errorText={touched.firstName && errors.firstName}
                  required
                />
              </PaddingWrapper>
              <PaddingWrapper>
                <Field
                  label="Pavardė"
                  id="lastName"
                  name="lastName"
                  type="text"
                  as={Input}
                  fullWidth
                  error={errors.lastName && touched.lastName}
                  errorText={touched.lastName && errors.lastName}
                  required
                />
              </PaddingWrapper>
              <PaddingWrapper>
                <Field
                  label="Asmens kodas"
                  id="personalID"
                  name="personalID"
                  type="text"
                  as={Input}
                  fullWidth
                  error={errors.personalID && touched.personalID}
                  errorText={touched.personalID && errors.personalID}
                  required
                />
              </PaddingWrapper>
              <PaddingWrapper>
                <Field
                  label="Išsilavinimas"
                  id="educationLevel"
                  name="educationLevel"
                  as={Select}
                  withBlankSelect
                  fullWidth
                  error={errors.educationLevel && touched.educationLevel}
                  errorText={touched.educationLevel && errors.educationLevel}
                  required
                >
                  {Object.keys(requestTranslations.educationLevel).map((educationLevel) => (
                    <option value={educationLevel} key={educationLevel}>
                      {requestTranslations.educationLevel[educationLevel]}
                    </option>
                  ))}
                </Field>
              </PaddingWrapper>
              <PaddingWrapper>
                <Field
                  label="Veikla"
                  id="occupationType"
                  name="occupationType"
                  as={Select}
                  withBlankSelect
                  fullWidth
                  error={errors.occupationType && touched.occupationType}
                  errorText={touched.occupationType && errors.occupationType}
                  required
                >
                  {Object.keys(requestTranslations.occupationType).map((occupationType) => (
                    <option value={occupationType} key={occupationType}>
                      {requestTranslations.occupationType[occupationType]}
                    </option>
                  ))}
                </Field>
              </PaddingWrapper>
              <PaddingWrapper>
                <Field
                  label="Darbo stažas paskutinėje darbovietėje"
                  id="workExperience"
                  name="workExperience"
                  as={Select}
                  withBlankSelect
                  fullWidth
                  error={errors.workExperience && touched.workExperience}
                  errorText={touched.workExperience && errors.workExperience}
                  required
                >
                  {Object.keys(requestTranslations.workExperience).map((workExperience) => (
                    <option value={workExperience} key={workExperience}>
                      {requestTranslations.workExperience[workExperience]}
                    </option>
                  ))}
                </Field>
              </PaddingWrapper>

              {["working-abroad", "self-employed", "other"].includes(values.occupationType) && (
                <PaddingWrapper id="documentFilePaths">
                  <FileUpload
                    setter={(files) => setFieldValue("documentFilePaths", files)}
                    error={errors.documentFilePaths && touched.documentFilePaths}
                    errorText={errors.documentFilePaths}
                    title="Pajamas pagrindžiantys dokumentai"
                    description="Prašome pateikti pajamas pagrindžiančius dokumentus už paskutinius 4 mėnesius."
                    requirements={[
                      "Galite įkelti ne daugiau kaip tris failus.",
                      "Maksimalus vieno failo dydis 8MB.",
                      "Galimi formatai: .jpg .jpeg .gif .png ir .pdf",
                    ]}
                    files={values.documentFilePaths}
                    maxFiles={3}
                  />
                </PaddingWrapper>
              )}

              <PaddingWrapper></PaddingWrapper>
              <Typography.Text size="lead" className="mb-0">
                Pajamos ir išlaidos
              </Typography.Text>
              <PaddingWrapper>
                <Field
                  label="Pajamos"
                  id="personalIncome"
                  name="personalIncome"
                  type="number"
                  as={Input}
                  fullWidth
                  error={errors.personalIncome && touched.personalIncome}
                  errorText={touched.personalIncome && errors.personalIncome}
                  required
                />
              </PaddingWrapper>
              <PaddingWrapper>
                <Field
                  label="Finansiniai įsipareigojimai"
                  id="financialObligations"
                  name="financialObligations"
                  type="number"
                  as={Input}
                  fullWidth
                  error={errors.financialObligations && touched.financialObligations}
                  errorText={touched.financialObligations && errors.financialObligations}
                  required
                />
              </PaddingWrapper>
              <PaddingWrapper>
                <Field
                  label="Būsto paskolos įmoka"
                  id="housingObligations"
                  name="housingObligations"
                  type="number"
                  as={Input}
                  fullWidth
                  error={errors.housingObligations && touched.housingObligations}
                  errorText={touched.housingObligations && errors.housingObligations}
                  required
                />
              </PaddingWrapper>
              <PaddingWrapper></PaddingWrapper>
              <Typography.Text size="lead" justify className="mb-0">
                Ar Jums yra žinoma, kad Jūsų mėnesinės pajamos arba įsipareigojimai ateityje keisis?
              </Typography.Text>
              <PaddingWrapper>
                <RadioInput
                  type="radio"
                  name="futureFinancialSituationChange"
                  label="Taip"
                  value={true}
                  onClick={() => setFieldValue("futureFinancialSituationChange", true)}
                  checked={values.futureFinancialSituationChange}
                />

                <RadioInput
                  type="radio"
                  name="futureFinancialSituationChange"
                  label="Ne"
                  value={false}
                  onClick={() => setFieldValue("futureFinancialSituationChange", false)}
                  checked={!values.futureFinancialSituationChange}
                />
              </PaddingWrapper>
              {values.futureFinancialSituationChange && (
                <>
                  <PaddingWrapper>
                    <Field
                      label="Pajamos ateityje"
                      id="futurePersonalIncome"
                      name="futurePersonalIncome"
                      type="number"
                      as={Input}
                      fullWidth
                      error={errors.futurePersonalIncome && touched.futurePersonalIncome}
                      errorText={touched.futurePersonalIncome && errors.futurePersonalIncome}
                      required
                    />
                  </PaddingWrapper>

                  <PaddingWrapper>
                    <Field
                      label="Įsipareigojimai ateityje"
                      id="futureFinancialObligations"
                      name="futureFinancialObligations"
                      type="number"
                      as={Input}
                      fullWidth
                      error={errors.futureFinancialObligations && touched.futureFinancialObligations}
                      errorText={touched.futureFinancialObligations && errors.futureFinancialObligations}
                      required
                    />
                  </PaddingWrapper>
                </>
              )}

              <PaddingWrapper></PaddingWrapper>
              <Typography.Text size="lead" justify className="mb-0">
                Ar esate politiškai pažeidžiamas arba politiškai pažeidžiamo asmens giminaitis?
                <p onClick={toggleHide} className="info">
                  {" "}
                  <img src={InfoSign} alt=""></img>
                  {hide && (
                    <Card className={`boxText stack-top`}>
                      <img src={Cross} alt=""></img>
                      <p className="">
                        Politiškai pažeidžiamu asmeniu yra laikomas fizinis asmuo arba jo atstovas, jų artimieji šeimos
                        nariai ar jų artimi pagalbininkai, kurie šiuo metu arba paskutinius 12 mėnesių ėjo svarbias
                        viešąsias pareigas bet kuriose valstybėse ir/arba tarptautinėse ar užsienio valstybių
                        institucijose.
                      </p>
                    </Card>
                  )}
                </p>
              </Typography.Text>

              <PaddingWrapper>
                <RadioInput
                  type="radio"
                  name="isRelatedToPolitics"
                  label="Taip"
                  value={true}
                  onClick={() => setFieldValue("isRelatedToPolitics", true)}
                  checked={values.isRelatedToPolitics}
                />

                <RadioInput
                  type="radio"
                  name="isRelatedToPolitics"
                  label="Ne"
                  value={false}
                  onClick={() => setFieldValue("isRelatedToPolitics", false)}
                  checked={!values.isRelatedToPolitics}
                />
              </PaddingWrapper>

              {values.isRelatedToPolitics && (
                <>
                  <PaddingWrapper></PaddingWrapper>
                  <Typography.Text size="lead" justify className="mb-0">
                    Kas yra politiškai pažeidžiamas asmuo?
                  </Typography.Text>

                  <PaddingWrapper>
                    <RadioInput
                      type="radio"
                      name="isRelatedToPoliticsHimself"
                      label="Esu pats"
                      value={true}
                      onClick={() => setFieldValue("isRelatedToPoliticsHimself", true)}
                      checked={values.isRelatedToPoliticsHimself}
                    />

                    <RadioInput
                      type="radio"
                      name="isRelatedToPoliticsHimself"
                      label="Kitas asmuo"
                      value={false}
                      onClick={() => setFieldValue("isRelatedToPoliticsHimself", false)}
                      checked={!values.isRelatedToPoliticsHimself}
                    />
                  </PaddingWrapper>

                  <PaddingWrapper></PaddingWrapper>
                  <Typography.Text size="lead" className="mb-0">
                    Politiškai pažeidžiamo asmens duomenys
                  </Typography.Text>

                  {!values.isRelatedToPoliticsHimself && (
                    <>
                      <PaddingWrapper>
                        <Field
                          label="Vardas"
                          id="politicallyActivePersonFirstName"
                          name="politicallyActivePersonFirstName"
                          type="text"
                          as={Input}
                          fullWidth
                          error={errors.politicallyActivePersonFirstName && touched.politicallyActivePersonFirstName}
                          errorText={
                            touched.politicallyActivePersonFirstName && errors.politicallyActivePersonFirstName
                          }
                          required
                        />
                      </PaddingWrapper>

                      <PaddingWrapper>
                        <Field
                          label="Pavardė"
                          id="politicallyActivePersonLastName"
                          name="politicallyActivePersonLastName"
                          type="text"
                          as={Input}
                          fullWidth
                          error={errors.politicallyActivePersonLastName && touched.politicallyActivePersonLastName}
                          errorText={touched.politicallyActivePersonLastName && errors.politicallyActivePersonLastName}
                          required
                        />
                      </PaddingWrapper>
                    </>
                  )}

                  <PaddingWrapper>
                    <Field
                      label="Pareigos"
                      id="politicallyActivePersonDuties"
                      name="politicallyActivePersonDuties"
                      type="text"
                      as={Input}
                      fullWidth
                      error={errors.politicallyActivePersonDuties && touched.politicallyActivePersonDuties}
                      errorText={touched.politicallyActivePersonDuties && errors.politicallyActivePersonDuties}
                      required
                    />
                  </PaddingWrapper>
                </>
              )}

              <PaddingWrapper></PaddingWrapper>
              <Typography.Text size="lead" justify className="mb-0">
                Ar esate tikrasis paskolos naudos gavėjas?
              </Typography.Text>

              <PaddingWrapper>
                <RadioInput
                  type="radio"
                  name="beneficialOwner"
                  label="Taip"
                  value={true}
                  onClick={() => setFieldValue("beneficialOwner", true)}
                  checked={values.beneficialOwner}
                />

                <RadioInput
                  type="radio"
                  name="beneficialOwner"
                  label="Ne"
                  value={false}
                  onClick={() => setFieldValue("beneficialOwner", false)}
                  checked={!values.beneficialOwner}
                />
              </PaddingWrapper>

              <PaddingWrapper></PaddingWrapper>
              <Typography.Text size="lead" justify className="mb-0">
                Ar turite nekilnojamojo turto?
              </Typography.Text>

              <PaddingWrapper>
                <RadioInput
                  type="radio"
                  name="ownsRealEstate"
                  label="Taip"
                  value={true}
                  onClick={() => setFieldValue("ownsRealEstate", true)}
                  checked={values.ownsRealEstate}
                />

                <RadioInput
                  type="radio"
                  name="ownsRealEstate"
                  label="Ne"
                  value={false}
                  onClick={() => setFieldValue("ownsRealEstate", false)}
                  checked={!values.ownsRealEstate}
                />
              </PaddingWrapper>

              <PaddingWrapper></PaddingWrapper>
              <Typography.Text size="lead" justify className="mb-0">
                Ar turite savo vardu registruotą automobilį?
              </Typography.Text>

              <PaddingWrapper>
                <RadioInput
                  type="radio"
                  name="ownsCar"
                  label="Taip"
                  value={true}
                  onClick={() => setFieldValue("ownsCar", true)}
                  checked={values.ownsCar}
                />

                <RadioInput
                  type="radio"
                  name="ownsCar"
                  label="Ne"
                  value={false}
                  onClick={() => setFieldValue("ownsCar", false)}
                  checked={!values.ownsCar}
                />
              </PaddingWrapper>

              <ErrorFocus />

              <PaddingWrapper>
                <Button
                  type="submit"
                  fullWidth
                  id="step2"
                  {...(isValid
                    ? {
                        className: "is-valid",
                      }
                    : {})}
                >
                  <ButtonAdornment end>
                    <HiOutlineArrowNarrowRight />
                  </ButtonAdornment>
                  Tęsti
                </Button>
              </PaddingWrapper>
            </Form>
          )}
        </Formik>
      </FormRow>
    </>
  );
};

export default PersonalInfoStep1;
