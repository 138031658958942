import React from "react";
import {
  PaddingWrapper,
  Typography,
  Input,
  Button,
  Select,
  RadioInput,
  ButtonAdornment,
  Href,
} from "../../../ui";
import { ErrorFocus, FormRow, RequestStepperHeader } from "../../../components";
import { Formik, Field, Form } from "formik";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import * as yup from "yup";
import errors from "../../../locale/lt_LT/errors";
import Checkbox from "../../../ui/Checkbox/Checkbox";
import validatePersonalId from "../../../helpers/validatePersonalId";

const lithuanianMunicipalities = require("../../../data/lithuanianMunicipalities")
  .default;

const validationSchema = yup.object().shape({
  residenceMunicipality: yup
    .string(errors.invalidFormat)
    .required(errors.required),
  residenceCity: yup.string(errors.invalidFormat).required(errors.required),
  // residenceAddress: yup.string(errors.invalidFormat).required(errors.required),
  residenceStreetAddress: yup
    .string(errors.invalidFormat)
    .required(errors.required),
  residenceHouseNumber: yup
    .string(errors.invalidFormat)
    .required(errors.required),
  residenceFlatNumber: yup.string(errors.invalidFormat),
  residencePostalCode: yup
    .string(errors.invalidFormat)
    .matches(/^\d+$/, errors.invalidFormat)
    .min(5, errors.invalidFormat)
    .max(5, errors.invalidFormat)
    .required(errors.required),

  childrenCount: yup
    .number(errors.invalidFormat)
    .min(0, errors.invalidFormat)
    .required(errors.required),
  maritalStatus: yup.string(errors.invalidFormat).required(errors.required),
  withCoApplicant: yup.bool(errors.invalidFormat).required(errors.required),

  // partner stuff
  partnerFirstName: yup.string(errors.invalidFormat).when("withCoApplicant", {
    is: true,
    then: yup
      .string(errors.invalidFormat)
      .test("only-letters", errors.invalidFormat, (val) => {
        return !/\d/.test(val);
      })
      .required(errors.required),
  }),
  partnerLastName: yup.string(errors.invalidFormat).when("withCoApplicant", {
    is: true,
    then: yup
      .string(errors.invalidFormat)
      .test("only-letters", errors.invalidFormat, (val) => {
        return !/\d/.test(val);
      })
      .required(errors.required),
  }),
  partnerContactNumber: yup
    .string(errors.invalidFormat)
    .when("withCoApplicant", {
      is: true,
      then: yup
        .string(errors.invalidFormat)
        .matches(/^[0-9]+$/, errors.invalidFormat)
        .min(8, errors.invalidFormat)
        .max(8, errors.invalidFormat)
        .required(errors.required),
    }),
  partnerEmail: yup.string(errors.invalidFormat).when("withCoApplicant", {
    is: true,
    then: yup
      .string(errors.invalidFormat)
      .email(errors.invalidFormat)
      .required(errors.required),
  }),
  partnerPersonalID: yup.string(errors.invalidFormat).when("withCoApplicant", {
    is: true,
    then: yup
      .string(errors.invalidFormat)
      .matches(/^[0-9]+$/, errors.invalidFormat)
      .min(11, errors.invalidFormat)
      .max(11, errors.invalidFormat)
      .required(errors.required)
      .test("validate-personal-id", function (personalId) {
        const { path, createError } = this;
        let isValid = true;

        try {
          isValid = validatePersonalId(personalId);
        } catch {
          isValid = false;
        }

        return isValid
          ? true
          : createError({ path, message: errors.invalidFormat });
      }),
  }),
  partnerPersonalIncome: yup
    .number(errors.invalidFormat)
    .when("withCoApplicant", {
      is: true,
      then: yup
        .number(errors.invalidFormat)
        .min(0, errors.invalidFormat)
        .required(errors.required),
    }),
  partnerFinancialObligations: yup
    .number(errors.invalidFormat)
    .when("withCoApplicant", {
      is: true,
      then: yup
        .number(errors.invalidFormat)
        .min(0, errors.invalidFormat)
        .required(errors.required),
    }),
  rulesAccepted: yup.bool().oneOf([true], errors.required),
  personalInfoAccepted: yup.bool().oneOf([true], errors.required),
  privacyAccepted: yup.bool().oneOf([true], errors.required),
});

const PersonalInfoStep2 = ({ values, setValues, geolocation = {} }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <>
      <FormRow>
        <Formik
          initialValues={values}
          onSubmit={(values) => {
            setIsLoading(true);
            setValues(values).finally(() => setIsLoading(false));
          }}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors, touched, isValid }) => (
            <Form>
              <RequestStepperHeader
                step={3}
                title="Asmeniniai duomenys"
                totalSteps={
                  values.maritalStatus === "married" && values.withCoApplicant
                    ? 5
                    : 4
                }
              />

              <Typography.Text size="lead" className="mb-0">
                Gyvenamoji vieta
              </Typography.Text>

              {/* <PaddingWrapper>
                <Field
                  label="Gatvė, namas, butas"
                  id="residenceAddress"
                  name="residenceAddress"
                  type="text"
                  component={LocationInput}
                  fullWidth
                  error={errors.residenceAddress && touched.residenceAddress}
                  errorText={
                    touched.residenceAddress && errors.residenceAddress
                  }
                  required
                  autoComplete="off"
                />
              </PaddingWrapper> */}

              <PaddingWrapper>
                <Field
                  label="Gatvė"
                  id="residenceStreetAddress"
                  name="residenceStreetAddress"
                  type="text"
                  as={Input}
                  fullWidth
                  error={
                    errors.residenceStreetAddress &&
                    touched.residenceStreetAddress
                  }
                  errorText={
                    touched.residenceStreetAddress &&
                    errors.residenceStreetAddress
                  }
                  required
                  autoComplete="address-level-1"
                />
              </PaddingWrapper>

              <PaddingWrapper>
                <Field
                  label="Namas"
                  id="residenceHouseNumber"
                  name="residenceHouseNumber"
                  type="text"
                  as={Input}
                  fullWidth
                  error={
                    errors.residenceHouseNumber && touched.residenceHouseNumber
                  }
                  errorText={
                    touched.residenceHouseNumber && errors.residenceHouseNumber
                  }
                  required
                  autoComplete="address-level-1"
                />
              </PaddingWrapper>

              <PaddingWrapper>
                <Field
                  label="Butas (nebūtina)"
                  id="residenceFlatNumber"
                  name="residenceFlatNumber"
                  type="text"
                  as={Input}
                  fullWidth
                  error={
                    errors.residenceFlatNumber && touched.residenceFlatNumber
                  }
                  errorText={
                    touched.residenceFlatNumber && errors.residenceFlatNumber
                  }
                  autoComplete="address-level-1"
                />
              </PaddingWrapper>

              <PaddingWrapper>
                <Field
                  label="Pašto kodas"
                  id="residencePostalCode"
                  name="residencePostalCode"
                  type="text"
                  as={Input}
                  fullWidth
                  error={
                    errors.residencePostalCode && touched.residencePostalCode
                  }
                  errorText={
                    touched.residencePostalCode && errors.residencePostalCode
                  }
                  startAdornment="LT-"
                  placeholder="00000"
                  required
                  autoComplete="address-level-1"
                />
              </PaddingWrapper>

              <PaddingWrapper>
                <Field
                  label="Miestas"
                  id="residenceCity"
                  name="residenceCity"
                  type="text"
                  as={Input}
                  fullWidth
                  error={errors.residenceCity && touched.residenceCity}
                  errorText={touched.residenceCity && errors.residenceCity}
                  required
                  autoComplete="address-level2"
                />
              </PaddingWrapper>

              <PaddingWrapper>
                <Field
                  label="Savivaldybė"
                  id="residenceMunicipality"
                  name="residenceMunicipality"
                  as={Select}
                  withBlankSelect
                  fullWidth
                  error={
                    errors.residenceMunicipality &&
                    touched.residenceMunicipality
                  }
                  errorText={
                    touched.residenceMunicipality &&
                    errors.residenceMunicipality
                  }
                  required
                >
                  {lithuanianMunicipalities.map((municipality) => (
                    <option value={municipality} key={municipality}>
                      {municipality}
                    </option>
                  ))}
                </Field>
              </PaddingWrapper>

              <PaddingWrapper></PaddingWrapper>
              <Typography.Text size="lead" className="mb-0">
                Šeiminė padėtis
              </Typography.Text>

              <PaddingWrapper>
                <Field
                  label="Vaikų / išlaikytinių skaičius"
                  id="childrenCount"
                  name="childrenCount"
                  type="number"
                  as={Input}
                  fullWidth
                  error={errors.childrenCount && touched.childrenCount}
                  errorText={touched.childrenCount && errors.childrenCount}
                  required
                />
              </PaddingWrapper>

              <PaddingWrapper>
                <Field
                  type="radio"
                  name="maritalStatus"
                  value="married"
                  label="Vedęs / ištekėjusi"
                  as={RadioInput}
                  fullWidth
                />

                <Field
                  type="radio"
                  name="maritalStatus"
                  value="not-married"
                  label="Nevedęs / neištekėjusi"
                  as={RadioInput}
                  fullWidth
                />
              </PaddingWrapper>

              {values.maritalStatus === "married" && (
                <>
                  <PaddingWrapper></PaddingWrapper>

                  <Typography.Text size="lead" justify className="mb">
                    Paskolą imsite kartu su sutuoktiniu / sutuoktine?
                  </Typography.Text>

                  <Typography.Text size="small" grey justify>
                    Tikimybė gauti kreditą pildant kartu su sutuoktiniu(-e) yra
                    didesnė bei padidėja galimybė gauti paskolą dar geresnėmis
                    sąlygomis.
                  </Typography.Text>

                  <PaddingWrapper>
                    <RadioInput
                      type="radio"
                      name="withCoApplicant"
                      label="Taip"
                      value={true}
                      onClick={() => setFieldValue("withCoApplicant", true)}
                      checked={values.withCoApplicant}
                    />

                    <RadioInput
                      type="radio"
                      name="withCoApplicant"
                      label="Ne"
                      value={false}
                      onClick={() => setFieldValue("withCoApplicant", false)}
                      checked={!values.withCoApplicant}
                    />
                  </PaddingWrapper>

                  {values.withCoApplicant && (
                    <>
                      <PaddingWrapper></PaddingWrapper>
                      <Typography.Text size="lead" justify className="mb">
                        Informacija apie sutuoktinį
                      </Typography.Text>

                      <PaddingWrapper>
                        <Field
                          label="Vardas"
                          id="partnerFirstName"
                          name="partnerFirstName"
                          type="text"
                          as={Input}
                          fullWidth
                          error={
                            errors.partnerFirstName && touched.partnerFirstName
                          }
                          errorText={
                            touched.partnerFirstName && errors.partnerFirstName
                          }
                          required
                        />
                      </PaddingWrapper>

                      <PaddingWrapper>
                        <Field
                          label="Pavardė"
                          id="partnerLastName"
                          name="partnerLastName"
                          type="text"
                          as={Input}
                          fullWidth
                          error={
                            errors.partnerLastName && touched.partnerLastName
                          }
                          errorText={
                            touched.partnerLastName && errors.partnerLastName
                          }
                          required
                        />
                      </PaddingWrapper>

                      <PaddingWrapper>
                        <Field
                          label="Asmens kodas"
                          id="partnerPersonalID"
                          name="partnerPersonalID"
                          type="number"
                          as={Input}
                          fullWidth
                          error={
                            errors.partnerPersonalID &&
                            touched.partnerPersonalID
                          }
                          errorText={
                            touched.partnerPersonalID &&
                            errors.partnerPersonalID
                          }
                          required
                        />
                      </PaddingWrapper>

                      <PaddingWrapper>
                        <Field
                          label="Telefono numeris"
                          id="partnerContactNumber"
                          name="partnerContactNumber"
                          type="number"
                          as={Input}
                          fullWidth
                          error={
                            errors.partnerContactNumber &&
                            touched.partnerContactNumber
                          }
                          errorText={
                            touched.partnerContactNumber &&
                            errors.partnerContactNumber
                          }
                          startAdornment="+370"
                          autoComplete="off"
                          required
                        />
                      </PaddingWrapper>

                      <PaddingWrapper>
                        <Field
                          label="Elektroninis paštas"
                          id="partnerEmail"
                          name="partnerEmail"
                          type="email"
                          as={Input}
                          fullWidth
                          error={errors.partnerEmail && touched.partnerEmail}
                          errorText={
                            touched.partnerEmail && errors.partnerEmail
                          }
                          required
                        />
                      </PaddingWrapper>

                      <PaddingWrapper>
                        <Field
                          label="Pajamos"
                          id="partnerPersonalIncome"
                          name="partnerPersonalIncome"
                          type="number"
                          as={Input}
                          fullWidth
                          error={
                            errors.partnerPersonalIncome &&
                            touched.partnerPersonalIncome
                          }
                          errorText={
                            touched.partnerPersonalIncome &&
                            errors.partnerPersonalIncome
                          }
                          required
                        />
                      </PaddingWrapper>

                      <PaddingWrapper>
                        <Field
                          label="Įsipareigojimai"
                          id="partnerFinancialObligations"
                          name="partnerFinancialObligations"
                          type="number"
                          as={Input}
                          fullWidth
                          error={
                            errors.partnerFinancialObligations &&
                            touched.partnerFinancialObligations
                          }
                          errorText={
                            touched.partnerFinancialObligations &&
                            errors.partnerFinancialObligations
                          }
                          required
                        />
                      </PaddingWrapper>
                    </>
                  )}
                </>
              )}

              <PaddingWrapper size="large">
                <PaddingWrapper>
                  <Field
                    type="checkbox"
                    name="rulesAccepted"
                    id="rulesAccepted"
                    as={Checkbox}
                    label={
                      <>
                        Patvirtinu, kad susipažinau ir sutinku su{" "}
                        <Href
                          href="https://creditking.lt/wp-content/themes/blankslate/docs/SVETAINES_NAUDOJIMOSI_TAISYKLES.pdf"
                          target="_blank"
                          rel="noopener"
                        >
                          Credit King svetainės naudojimosi taisyklėmis
                        </Href>
                      </>
                    }
                    error={errors.rulesAccepted && touched.rulesAccepted}
                  />
                </PaddingWrapper>

                <PaddingWrapper>
                  <Field
                    type="checkbox"
                    name="personalInfoAccepted"
                    id="personalInfoAccepted"
                    as={Checkbox}
                    label={
                      <>
                        Patvirtinu, kad susipažinau ir sutinku su sutikimu dėl{" "}
                        <Href

                          href="https://creditking.lt/wp-content/uploads/2022/03/KLIENTO-SUTIKIMAS-DEL-ASMENS-DUOMENU-TVARKYMO-V19.pdf"

                          target="_blank"
                          rel="noopener"
                        >
                          asmens duomenų tvarkymo
                        </Href>
                      </>
                    }
                    error={
                      errors.personalInfoAccepted &&
                      touched.personalInfoAccepted
                    }
                  />
                </PaddingWrapper>

                <PaddingWrapper>
                  <Field
                    type="checkbox"
                    name="privacyAccepted"
                    id="privacyAccepted"
                    as={Checkbox}
                    label={
                      <>
                        Patvirtinu, kad susipažinau ir sutinku su{" "}
                        <Href
                          href="https://creditking.lt/wp-content/themes/blankslate/docs/Privatumo_politika.pdf"
                          target="_blank"
                          rel="noopener"
                        >
                          Credit King privatumo politika
                        </Href>
                      </>
                    }
                    error={errors.privacyAccepted && touched.privacyAccepted}
                  />
                </PaddingWrapper>
              </PaddingWrapper>

              <ErrorFocus />

              <PaddingWrapper>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isLoading}
                  id="step3"
                  {...(isValid
                    ? {
                        className: "is-valid",
                      }
                    : {})}
                >
                  <ButtonAdornment end>
                    <HiOutlineArrowNarrowRight />
                  </ButtonAdornment>
                  Tęsti
                </Button>
              </PaddingWrapper>
            </Form>
          )}
        </Formik>
      </FormRow>
    </>
  );
};

export default PersonalInfoStep2;
