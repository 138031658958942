import React from "react";
import styled from "styled-components";

const ProgressBar = styled.div`
  position: absolute;
  height: 4px;
  top: 0;
  width: ${({ width }) => width}vw;
  background: #4575f2;
`;

const TopProgressBar = ({ progress = 30 }) => {
  return <ProgressBar width={progress} />;
};

export default TopProgressBar;
