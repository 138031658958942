import React from "react";
import {
  ButtonAdornment,
  Input,
  PaddingWrapper,
  Select,
  Typography,
  Button,
} from "../../../../ui";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import errors from "../../../../locale/lt_LT/errors";
import { FileUpload } from "../../../../components";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { RequestApi } from "../../../../api";
import { toast } from "react-toastify";

const lithuanianMunicipalities = require("../../../../data/lithuanianMunicipalities")
  .default;

const realEstateValidationSchema = yup.object().shape({
  realEstateType: yup.string(errors.invalidFormat).required(errors.required),
  realEstateMunicipality: yup
    .string(errors.invalidFormat)
    .required(errors.required),
  realEstateCity: yup.string(errors.invalidFormat).required(errors.required),
  realEstateAddress: yup.string(errors.invalidFormat).required(errors.required),
  realEstatePostCode: yup
    .string(errors.invalidFormat)
    .required(errors.required),
  documents1: yup.array().of(yup.object()).required(errors.required),
  documents2: yup.array().of(yup.object()).required(errors.required),
});

const RealEstateExchangeSubview = ({ request, getRequest }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <>
      <Typography.Text size="lead">
        Įkeičiamo nekilnojamojo turto informacija
      </Typography.Text>
      <Formik
        initialValues={{
          realEstateType: "",
          realEstateMunicipality: "",
          realEstateCity: "",
          realEstateAddress: "",
          realEstatePostCode: "",
          documents1: [],
          documents2: [],
        }}
        onSubmit={(values) => {
          setIsLoading(true);

          values.realEstateDocuments = [
            ...values.documents1,
            ...values.documents2,
          ];

          RequestApi.patchExchangeInfo(request._id, values)
            .then(async () => {
              await getRequest();
              toast.dark("Paraiška sėkmingai atnaujinta");
            })
            .catch((error) => {
              toast.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        isInitialValid={false}
        validationSchema={realEstateValidationSchema}
      >
        {({ errors, touched, setFieldValue, values, isValid }) => (
          <Form>
            <PaddingWrapper>
              <Field
                label="Tipas"
                id="realEstateType"
                name="realEstateType"
                as={Select}
                withBlankSelect
                fullWidth
                error={errors.realEstateType && touched.realEstateType}
                errorText={touched.realEstateType && errors.realEstateType}
                required
              >
                <option value="flat">Butas</option>
                <option value="house">Namas</option>
                <option value="office">Biuras</option>
                <option value="warehouse">Sandėlis</option>
                <option value="commercial-facilities">
                  Komercinės patalpos
                </option>
                <option value="investment-object">Investicinis objektas</option>
                <option value="production-facilities">
                  Gamybinės patalpos
                </option>
                <option value="land">Žemė</option>
              </Field>
            </PaddingWrapper>

            <PaddingWrapper>
              <Field
                label="Savivaldybė"
                id="realEstateMunicipality"
                name="realEstateMunicipality"
                as={Select}
                withBlankSelect
                fullWidth
                error={
                  errors.realEstateMunicipality &&
                  touched.realEstateMunicipality
                }
                errorText={
                  touched.realEstateMunicipality &&
                  errors.realEstateMunicipality
                }
                required
              >
                {lithuanianMunicipalities.map((municipality) => (
                  <option value={municipality} key={municipality}>
                    {municipality}
                  </option>
                ))}
              </Field>
            </PaddingWrapper>

            <PaddingWrapper>
              <Field
                label="Miestas"
                id="realEstateCity"
                name="realEstateCity"
                type="text"
                as={Input}
                fullWidth
                error={errors.realEstateCity && touched.realEstateCity}
                errorText={touched.realEstateCity && errors.realEstateCity}
                required
              />
            </PaddingWrapper>

            <PaddingWrapper>
              <Field
                label="Adresas"
                id="realEstateAddress"
                name="realEstateAddress"
                type="text"
                as={Input}
                fullWidth
                error={errors.realEstateAddress && touched.realEstateAddress}
                errorText={
                  touched.realEstateAddress && errors.realEstateAddress
                }
                required
              />
            </PaddingWrapper>

            <PaddingWrapper>
              <Field
                label="Pašto kodas"
                id="realEstatePostCode"
                name="realEstatePostCode"
                type="text"
                as={Input}
                fullWidth
                error={errors.realEstatePostCode && touched.realEstatePostCode}
                errorText={
                  touched.realEstatePostCode && errors.realEstatePostCode
                }
                required
              />
            </PaddingWrapper>

            <PaddingWrapper>
              <FileUpload
                setter={(files) => setFieldValue("documents1", files)}
                title="Nekilnojamojo turto registro išrašas"
                // description="Prašome pateikti pajamas pagrindžiančius dokumentus už paskutinius 4 mėnesius."
                requirements={[
                  "Galite įkelti ne daugiau kaip 5 failus.",
                  "Maksimalus vieno failo dydis 8MB.",
                  "Galimi formatai: .jpg .jpeg .gif .png ir .pdf",
                ]}
                files={values.documents1}
                maxFiles={5}
              />
            </PaddingWrapper>

            <PaddingWrapper>
              <FileUpload
                setter={(files) => setFieldValue("documents2", files)}
                title="Nekilnojamojo turto vertinimo ataskaita arba nekilnojamojo turto nuotraukos"
                // description="Prašome pateikti pajamas pagrindžiančius dokumentus už paskutinius 4 mėnesius."
                requirements={[
                  "Galite įkelti ne daugiau kaip 5 failus.",
                  "Maksimalus vieno failo dydis 8MB.",
                  "Galimi formatai: .jpg .jpeg .gif .png ir .pdf",
                ]}
                files={values.documents2}
                maxFiles={5}
              />
            </PaddingWrapper>

            <PaddingWrapper>
              <Button type="submit" disabled={isLoading || !isValid} fullWidth>
                <ButtonAdornment end>
                  <HiOutlineArrowNarrowRight />
                </ButtonAdornment>
                Tęsti
              </Button>
            </PaddingWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RealEstateExchangeSubview;
