import React from "react";
import { Badge } from "../../../ui";

const OfferStatusBadge = ({ status }) => {
  const style = {
    verticalAlign: "5px",
    marginLeft: "8px",
  };

  const badgeStatus = {
    waiting_offer: {},
    declined: {
      danger: true,
    },
    accepted: {
      success: true,
    },
    waiting_info: {
      primary: true,
    },
  };

  const translation = {
    waiting_offer: "Laukiama atsakymo",
    declined: "Paskola negalima",
    accepted: "Pasiūlymas pateiktas",
    waiting_info: "Laukia dokumentų",
  };

  return (
    <Badge {...badgeStatus[status]} className="offer-status" style={style}>
      {translation[status]}
    </Badge>
  );
};

export default OfferStatusBadge;
