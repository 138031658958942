import styled, { css } from "styled-components";

const Badge = styled.div`
  padding: 5px 12px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  white-space: pre;

  /* default */
  background: #aaacb6;

  ${({ primary }) =>
    primary &&
    css`
      background: #608cff;
    `}

  ${({ success }) =>
    success &&
    css`
      background: #58ce79;
    `}

  ${({ danger }) =>
    danger &&
    css`
      background: #f58f8f;
    `}

  ${({ warning }) =>
    warning &&
    css`
      background: #ffd600;
    `}
`;

export default Badge;
