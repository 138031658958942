import React from "react";
import { Button, ButtonAdornment } from "../../../ui";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link } from "react-router-dom";
import { ReactComponent as HamburgerLogo } from "../../../assets/img/hamburger.svg";
import { Visible } from "react-grid-system";
import { IoMdAdd } from "react-icons/io";
import { CgFileDocument } from "react-icons/cg";
import { UserContext } from "../../../context/UserContext";
import { ReactComponent as NotificationLogo } from "../../../assets/img/notification.svg";
import { ImAttachment } from "react-icons/im";

const MobileMenu = ({ activeRoute = "requests" }) => {
  const userContext = React.useContext(UserContext);
  let { requiredDocuments } = userContext.user;

  if (!requiredDocuments) requiredDocuments = [];

  let anyDocumentsToUpload = false;

  requiredDocuments.forEach((document) => {
    if (!document.files || !document.files.length) {
      anyDocumentsToUpload = true;
    }
  });

  return (
    <Visible xs sm md lg>
      <Popup
        position="bottom right"
        closeOnDocumentClick
        contentStyle={{
          width: "300px",
        }}
        arrow={false}
        trigger={
          <Button link className="">
            <HamburgerLogo className="va-middle" />
          </Button>
        }
      >
        <div className="menu fade-in px-4 py-2">
          <div>
            <Link to="/">
              <Button
                size="large"
                active={activeRoute === "requests"}
                link
                left
                fullWidth
              >
                <CgFileDocument
                  className="pr-2"
                  size={20}
                  fill="#4575F2"
                  style={{
                    verticalAlign: "-3px",
                  }}
                />
                Mano paraiškos
              </Button>
            </Link>
          </div>

          <div className="mt-3">
            <Link to="/documents">
              <Button
                size="large"
                active={activeRoute === "documents"}
                link
                left
                fullWidth
              >
                <ImAttachment
                  className="pr-2"
                  size={18}
                  fill="#4575F2"
                  style={{
                    verticalAlign: "-3px",
                  }}
                />
                Dokumentai
                {anyDocumentsToUpload && (
                  <ButtonAdornment end>
                    <NotificationLogo
                      style={{
                        verticalAlign: "9px",
                      }}
                    />
                  </ButtonAdornment>
                )}
              </Button>
            </Link>
          </div>

          <div className="mt-4">
            <Link to="/submit">
              <Button size="large" fullWidth>
                <ButtonAdornment end>
                  <IoMdAdd />
                </ButtonAdornment>
                Nauja paraiška
              </Button>
            </Link>
          </div>
        </div>
      </Popup>
    </Visible>
  );
};

export default MobileMenu;
