import React from "react";
import {
  PaddingWrapper,
  Typography,
  Input,
  Select,
  Button,
  ButtonAdornment,
  Alert,
  RadioInput,
} from "../../../ui";
import {
  ErrorFocus,
  FileUpload,
  FormRow,
  RequestStepperHeader,
} from "../../../components";
import { Formik, Field, Form } from "formik";
import styled, { css } from "styled-components";
import { Col, Row } from "react-grid-system";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import * as yup from "yup";
import errors from "../../../locale/lt_LT/errors";
import { ReactComponent as SpendingLogo } from "../../../assets/img/spending.svg";
import { ReactComponent as CarLogo } from "../../../assets/img/car.svg";
import { ReactComponent as RefinancingLogo } from "../../../assets/img/refinancing.svg";
import { ReactComponent as ShieldLogo } from "../../../assets/img/shield.svg";
import { ReactComponent as LandLotLogo } from "../../../assets/img/land-lot.svg";
import { ReactComponent as HouseMaintenanceLogo } from "../../../assets/img/house-maintenance.svg";

const requestTranslations = require("../../../locale/lt_LT/request").default;

const CreditTypeContainer = styled.button`
  cursor: pointer;
  display: block;
  border: 1px solid transparent;

  background: #f8f8f8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px 20px;
  height: 100%;
  width: 100%;
  text-align: left;
  font-size: 16px;

  :focus {
    outline: none;
  }

  ${({ active }) =>
    active &&
    css`
      border: 1px solid #4575f2;
      background: #f6f9ff;
    `}
`;

const CreditTypeSelector = ({ creditType, active, onClick }) => {
  const style = {
    verticalAlign: "middle",
    width: "48px",
    height: "48px",
    paddingRight: "15px",
  };

  const availableLogos = {
    car: <CarLogo style={style} />,
    spending: <SpendingLogo style={style} />,
    refinancing: <RefinancingLogo style={style} />,
    "land-lot": <LandLotLogo style={style} />,
    "house-maintenance": <HouseMaintenanceLogo style={style} />,
  };

  const Logo = availableLogos[creditType];

  return (
    <div
      style={{
        marginBottom: 15,
      }}
    >
      <CreditTypeContainer onClick={onClick} type="button" active={active}>
        <div
          style={{
            display: "block",
          }}
        >
          <div
            style={{
              display: "inline-block",
            }}
          >
            {Logo}
          </div>

          <div
            style={{
              display: "inline-block",
            }}
            className="my-auto h-100"
          >
            <Typography.Text left className="m-0">
              {requestTranslations.creditType[creditType]}
            </Typography.Text>
          </div>
        </div>
      </CreditTypeContainer>
    </div>
  );
};

const carYears = [];

for (let y = new Date().getFullYear(); y >= 1900; y--) {
  carYears.push(y);
}

const validationSchema = yup.object().shape({
  creditType: yup.string(errors.invalidFormat).required(errors.required),
  creditAmount: yup.number(errors.invalidFormat).when("creditAmountRange", {
    is: "other",
    then: yup
      .number(errors.invalidFormat)
      .min(300, "Mažiausia suma yra 300 €")
      .max(25000, "Didžiausia suma yra 25000 €")
      .required(errors.required),
  }),
  creditLength: yup.number(errors.invalidFormat).when("creditLengthRange", {
    is: "other",
    then: yup
      .number(errors.invalidFormat)
      .min(3, "Trumpiausias laikotarpis yra 3 mėn.")
      .max(84, "Ilgiausias laikotarpis yra 84 mėn.")
      .required(errors.required),
  }),
  carInfo: yup.string(errors.invalidFormat).when("creditType", {
    is: "car",
    then: yup.string(errors.invalidFormat).required(errors.required),
  }),
  carCreditType: yup.string(errors.invalidFormat).when("creditType", {
    is: "car",
    then: yup.string(errors.invalidFormat).required(errors.required),
  }),
  carManufacturingYear: yup.string(errors.invalidFormat).when("creditType", {
    is: "car",
    then: yup.string(errors.invalidFormat).required(errors.required),
  }),
  refinancingFilePaths: yup
    .array()
    .of(yup.object())
    .when("creditType", {
      is: "refinancing",
      then: yup.array().of(yup.object()).required(errors.requiredDocuments),
    }),
});

const CreditRequestStep = ({ values, setValues, step, registered }) => {
  const creditAmountRanges = [
    "1000",
    "3000",
    "5000",
    "10000",
    "15000",
    "other",
  ];
  const creditLengthRanges = ["6", "12", "24", "36", "48", "80", "other"];

  return (
    <>
      {registered && (
        <FormRow size="extraLarge">
          <Alert success>
            Jūsų Credit King paskyra sukurta. Paraiškos progresas bus išsaugomas
            automatiškai. Prisijungę, galėsite pildymą tęsti bet kada vėliau.
          </Alert>
        </FormRow>
      )}

      <FormRow size="extraLarge">
        <Typography.Text weight={500} size="small">
          <ShieldLogo className="pr-3 va-middle" />
          Paskolų palyginimas per Credit King platformą neturi įtakos kredito
          istorijai.
        </Typography.Text>
      </FormRow>

      <RequestStepperHeader step={1} title="Kokios paskolos norėtumėte?" />

      <Formik
        initialValues={values}
        onSubmit={(values) => {
          if (values.creditAmountRange !== "other") {
            values.creditAmount = parseInt(values.creditAmountRange);
          }

          if (values.creditLengthRange !== "other") {
            values.creditLength = parseInt(values.creditLengthRange);
          }

          setValues(values);
        }}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, errors, touched, isValid }) => (
          <Form>
            <Typography.Text size="lead" className="mb-2 mt-5">
              Kam skirta paskola?
            </Typography.Text>

            <FormRow size="extraLarge">
              <Row gutterWidth={30}>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  className="mt-3"
                >
                  <CreditTypeSelector
                    creditType="spending"
                    active={values.creditType === "spending"}
                    onClick={() => setFieldValue("creditType", "spending")}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  className="mt-3"
                >
                  <CreditTypeSelector
                    creditType="car"
                    active={values.creditType === "car"}
                    onClick={() => setFieldValue("creditType", "car")}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  className="mt-3"
                >
                  <CreditTypeSelector
                    creditType="refinancing"
                    active={values.creditType === "refinancing"}
                    onClick={() => setFieldValue("creditType", "refinancing")}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  className="mt-3"
                >
                  <CreditTypeSelector
                    creditType="land-lot"
                    active={values.creditType === "land-lot"}
                    onClick={() => setFieldValue("creditType", "land-lot")}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  className="mt-3"
                >
                  <CreditTypeSelector
                    creditType="house-maintenance"
                    active={values.creditType === "house-maintenance"}
                    onClick={() =>
                      setFieldValue("creditType", "house-maintenance")
                    }
                  />
                </Col>
              </Row>
            </FormRow>

            <FormRow>
              <Typography.Text size="lead">Paskolos suma</Typography.Text>

              {creditAmountRanges.map((amount) => (
                <PaddingWrapper margin="small">
                  <RadioInput
                    fullWidth
                    type="radio"
                    name="creditAmountRange"
                    label={amount === "other" ? "Kita suma" : amount + " €"}
                    value={amount}
                    onClick={() => setFieldValue("creditAmountRange", amount)}
                    checked={values.creditAmountRange === amount}
                  />
                </PaddingWrapper>
              ))}

              {values.creditAmountRange === "other" && (
                <PaddingWrapper margin="small">
                  <Field
                    label="Paskolos suma, 300 - 25000 €"
                    id="creditAmount"
                    name="creditAmount"
                    type="number"
                    as={Input}
                    fullWidth
                    error={errors.creditAmount && touched.creditAmount}
                    errorText={touched.creditAmount && errors.creditAmount}
                    required
                  />
                </PaddingWrapper>
              )}

              <Typography.Text size="lead">
                Paskolos laikotarpis
              </Typography.Text>

              {creditLengthRanges.map((amount) => (
                <PaddingWrapper margin="small">
                  <RadioInput
                    fullWidth
                    type="radio"
                    name="creditLengthRange"
                    label={
                      amount === "other"
                        ? "Kitas laikotarpis"
                        : amount + " mėn."
                    }
                    value={amount}
                    onClick={() => setFieldValue("creditLengthRange", amount)}
                    checked={values.creditLengthRange === amount}
                  />
                </PaddingWrapper>
              ))}

              {values.creditLengthRange === "other" && (
                <PaddingWrapper margin="small">
                  <Field
                    label="Laikotarpis, 3 - 84 mėn."
                    id="creditLength"
                    name="creditLength"
                    type="number"
                    as={Input}
                    fullWidth
                    error={errors.creditLength && touched.creditLength}
                    errorText={touched.creditLength && errors.creditLength}
                    required
                  />
                </PaddingWrapper>
              )}
            </FormRow>

            {values.creditType === "refinancing" && (
              <FormRow>
                <PaddingWrapper id="refinancingFilePaths">
                  <FileUpload
                    setter={(files) =>
                      setFieldValue("refinancingFilePaths", files)
                    }
                    title="Refinansavimo dokumentai"
                    description="Prašome pateikti finansinių įsipareigojimų (paskolų, lizingo, kt.), kuriuos norite refinansuoti, sutarčių kopijas."
                    requirements={[
                      "Galite įkelti ne daugiau kaip tris failus.",
                      "Maksimalus vieno failo dydis 8MB.",
                      "Galimi formatai: .jpg .jpeg .gif .png ir .pdf",
                    ]}
                    error={
                      errors.refinancingFilePaths &&
                      touched.refinancingFilePaths
                    }
                    errorText={errors.refinancingFilePaths}
                    files={values.refinancingFilePaths}
                    maxFiles={3}
                  />
                </PaddingWrapper>

                <PaddingWrapper />
              </FormRow>
            )}

            {values.creditType === "car" && (
              <>
                <Typography.Text size="lead">
                  Informacija apie automobilį
                </Typography.Text>

                <FormRow>
                  <PaddingWrapper>
                    <Field
                      label="Autolizingo tipas"
                      id="carCreditType"
                      name="carCreditType"
                      as={Select}
                      withBlankSelect
                      fullWidth
                      error={errors.carCreditType && touched.carCreditType}
                      errorText={touched.carCreditType && errors.carCreditType}
                      required
                    >
                      {Object.keys(requestTranslations.carCreditType).map(
                        (carCreditType) => (
                          <option value={carCreditType} key={carCreditType}>
                            {requestTranslations.carCreditType[carCreditType]}
                          </option>
                        )
                      )}
                    </Field>
                  </PaddingWrapper>

                  <PaddingWrapper>
                    <Field
                      label="Pagaminimo metai"
                      id="carManufacturingYear"
                      name="carManufacturingYear"
                      as={Select}
                      withBlankSelect
                      fullWidth
                      error={
                        errors.carManufacturingYear &&
                        touched.carManufacturingYear
                      }
                      errorText={
                        touched.carManufacturingYear &&
                        errors.carManufacturingYear
                      }
                      required
                    >
                      {carYears.map((year) => (
                        <option value={year} key={year}>
                          {year}
                        </option>
                      ))}
                    </Field>
                  </PaddingWrapper>
                </FormRow>

                <FormRow size="extraLarge">
                  <PaddingWrapper className="mt-0">
                    <Field
                      label="Informacija apie automobilį"
                      id="carInfo"
                      name="carInfo"
                      type="text"
                      as={Input}
                      fullWidth
                      required
                      error={errors.carInfo && touched.carInfo}
                      errorText={touched.carInfo && errors.carInfo}
                      helperText="Nurodykite automobilio markę, modelį, kw, tūrį, kėbulo tipą, pavarų dėžę arba nuorodą į automobilio skelbimą."
                    />
                  </PaddingWrapper>
                </FormRow>
              </>
            )}

            <ErrorFocus />

            <FormRow>
              <PaddingWrapper>
                <Button
                  type="submit"
                  fullWidth
                  id="step1"
                  {...(isValid
                    ? {
                        className: "is-valid",
                      }
                    : {})}
                >
                  <ButtonAdornment end>
                    <HiOutlineArrowNarrowRight />
                  </ButtonAdornment>
                  Tęsti
                </Button>
              </PaddingWrapper>
            </FormRow>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreditRequestStep;
