import React from "react";
import styled, { css } from "styled-components";

const LabelContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 6px;
  left: 0;
  height: 16px;
  width: 16px;
  background: #ffffff;
  border: 1px solid #4575f2;
  border-radius: 4px;

  ${({ error }) =>
    error &&
    css`
      border: 1px solid #d02b2b;
      background: #fff4f4;
    `}

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 3px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${Checkmark} {
    background-color: #4575f2;
    border-color: #4575f2;
  }

  &:checked ~ ${Checkmark}::after {
    display: block;
  }
`;

const Checkbox = (props) => (
  <LabelContainer>
    <span>{props.label}</span>

    <CheckboxInput {...props} />
    <Checkmark error={props.error} />
  </LabelContainer>
);

export default Checkbox;
