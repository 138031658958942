import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Input } from "../../ui";

import styled from "styled-components";
import convertGoogleLocation from "../../helpers/convertGoogleLocation";

const LocationSelector = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  margin-top: 5px;
  padding: 10px;
  z-index: 10000000;
  position: absolute;
  min-width: 90%;
  max-width: 90%;
`;

const SuggestionItem = styled.div`
  cursor: pointer;
  background: ${({ active }) => (active ? "#fafafa" : "#ffffff")};
  padding-top: 5px;
  padding-bottom: 5px;
`;

class LocationInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.field.name,
      address: props.value || "",
    };
  }

  handleChange = (address) => {
    this.setState(() => {
      this.props.form.setFieldTouched(`${this.state.name}`);
      this.props.form.setFieldValue(this.state.name, address);
      return { address };
    });
  };

  handleSelect = (address) => {
    // console.log(address.split(",")[0]);

    geocodeByAddress(address)
      .then((results) => {
        const closestAddress = results[0];

        if (!closestAddress) {
          return;
        }

        let municipality;
        let city;

        closestAddress.address_components.forEach((component) => {
          const { types = [] } = component;

          if (types.includes("administrative_area_level_2")) {
            municipality = convertGoogleLocation.fromGoogleLocation(
              component.short_name
            );
          } else if (types.includes("locality")) {
            city = component.short_name;
          }
        });

        if (municipality) {
          this.props.form.setFieldValue("residenceMunicipality", municipality);
        }

        if (city) {
          this.props.form.setFieldValue("residenceCity", city);
        }
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    const { props } = this;

    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={400}
        searchOptions={{
          types: ["address"],
          componentRestrictions: {
            country: ["lt"],
          },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input
              {...getInputProps()}
              {...{ ...props, onChange: getInputProps().onChange }}
            />

            {loading || suggestions.length > 0 ? (
              <LocationSelector>
                {suggestions.map((suggestion) => {
                  return (
                    <SuggestionItem
                      {...getSuggestionItemProps(suggestion)}
                      active={suggestion.active}
                    >
                      <span>{suggestion.description}</span>
                    </SuggestionItem>
                  );
                })}
              </LocationSelector>
            ) : (
              ""
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationInput;
