import React from "react";
import {
  ButtonAdornment,
  Input,
  PaddingWrapper,
  Typography,
  Button,
} from "../../../../ui";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import errors from "../../../../locale/lt_LT/errors";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { RequestApi } from "../../../../api";
import { toast } from "react-toastify";
import validatePersonalId from "../../../../helpers/validatePersonalId";

const carYears = [];

for (let y = new Date().getFullYear(); y >= 1900; y--) {
  carYears.push(y);
}

const partnerValidationSchema = yup.object().shape({
  partnerFirstName: yup
    .string(errors.invalidFormat)
    .test("only-letters", errors.invalidFormat, (val) => {
      return !/\d/.test(val);
    })
    .required(errors.required),
  partnerLastName: yup
    .string(errors.invalidFormat)
    .test("only-letters", errors.invalidFormat, (val) => {
      return !/\d/.test(val);
    })
    .required(errors.required),
  partnerContactNumber: yup
    .string(errors.invalidFormat)
    .matches(/^[0-9]+$/, errors.invalidFormat)
    .min(8, errors.invalidFormat)
    .max(8, errors.invalidFormat)
    .required(errors.required),
  partnerEmail: yup
    .string(errors.invalidFormat)
    .email(errors.invalidFormat)
    .required(errors.required),
  partnerPersonalID: yup
    .string(errors.invalidFormat)
    .matches(/^[0-9]+$/, errors.invalidFormat)
    .min(11, errors.invalidFormat)
    .max(11, errors.invalidFormat)
    .required(errors.required)
    .test("validate-personal-id", function (personalId) {
      const { path, createError } = this;
      let isValid = true;

      try {
        isValid = validatePersonalId(personalId);
      } catch {
        isValid = false;
      }

      return isValid
        ? true
        : createError({ path, message: errors.invalidFormat });
    }),
  partnerPersonalIncome: yup
    .number(errors.invalidFormat)
    .min(0, errors.invalidFormat)
    .required(errors.required),
  partnerFinancialObligations: yup
    .number(errors.invalidFormat)
    .min(0, errors.invalidFormat)
    .required(errors.required),
});

const AddPartnerSubview = ({ request, getRequest }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <>
      <Typography.Text size="lead">Sutuoktinio informacija</Typography.Text>
      <Formik
        initialValues={{
          partnerFirstName: "",
          partnerLastName: "",
          partnerContactNumber: "",
          partnerEmail: "",
          partnerPersonalID: "",
          partnerPersonalIncome: "",
          partnerFinancialObligations: "",
        }}
        onSubmit={(values) => {
          setIsLoading(true);

          RequestApi.patchPartnerInfo(request._id, values)
            .then(async () => {
              await getRequest();
              toast.dark("Paraiška sėkmingai atnaujinta");
            })
            .catch((error) => {
              toast.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        validationSchema={partnerValidationSchema}
      >
        {({ errors, touched }) => (
          <Form>
            <PaddingWrapper>
              <Field
                label="Vardas"
                id="partnerFirstName"
                name="partnerFirstName"
                type="text"
                as={Input}
                fullWidth
                error={errors.partnerFirstName && touched.partnerFirstName}
                errorText={touched.partnerFirstName && errors.partnerFirstName}
                required
              />
            </PaddingWrapper>

            <PaddingWrapper>
              <Field
                label="Pavardė"
                id="partnerLastName"
                name="partnerLastName"
                type="text"
                as={Input}
                fullWidth
                error={errors.partnerLastName && touched.partnerLastName}
                errorText={touched.partnerLastName && errors.partnerLastName}
                required
              />
            </PaddingWrapper>

            <PaddingWrapper>
              <Field
                label="Asmens kodas"
                id="partnerPersonalID"
                name="partnerPersonalID"
                type="number"
                as={Input}
                fullWidth
                error={errors.partnerPersonalID && touched.partnerPersonalID}
                errorText={
                  touched.partnerPersonalID && errors.partnerPersonalID
                }
                required
              />
            </PaddingWrapper>

            <PaddingWrapper>
              <Field
                label="Telefono numeris"
                id="partnerContactNumber"
                name="partnerContactNumber"
                type="number"
                as={Input}
                fullWidth
                error={
                  errors.partnerContactNumber && touched.partnerContactNumber
                }
                errorText={
                  touched.partnerContactNumber && errors.partnerContactNumber
                }
                startAdornment="+370"
                autoComplete="off"
                required
              />
            </PaddingWrapper>

            <PaddingWrapper>
              <Field
                label="Elektroninis paštas"
                id="partnerEmail"
                name="partnerEmail"
                type="email"
                as={Input}
                fullWidth
                error={errors.partnerEmail && touched.partnerEmail}
                errorText={touched.partnerEmail && errors.partnerEmail}
                required
              />
            </PaddingWrapper>

            <PaddingWrapper>
              <Field
                label="Pajamos"
                id="partnerPersonalIncome"
                name="partnerPersonalIncome"
                type="number"
                as={Input}
                fullWidth
                error={
                  errors.partnerPersonalIncome && touched.partnerPersonalIncome
                }
                errorText={
                  touched.partnerPersonalIncome && errors.partnerPersonalIncome
                }
                required
              />
            </PaddingWrapper>

            <PaddingWrapper>
              <Field
                label="Įsipareigojimai"
                id="partnerFinancialObligations"
                name="partnerFinancialObligations"
                type="number"
                as={Input}
                fullWidth
                error={
                  errors.partnerFinancialObligations &&
                  touched.partnerFinancialObligations
                }
                errorText={
                  touched.partnerFinancialObligations &&
                  errors.partnerFinancialObligations
                }
                required
              />
            </PaddingWrapper>

            <PaddingWrapper>
              <Button type="submit" disabled={isLoading} fullWidth>
                <ButtonAdornment end>
                  <HiOutlineArrowNarrowRight />
                </ButtonAdornment>
                Tęsti
              </Button>
            </PaddingWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddPartnerSubview;
