import React from "react";
import { Link, Redirect } from "react-router-dom";
import { RequestApi } from "../../api";
import { UserContext } from "../../context/UserContext";
import { DashboardLayout } from "../../layouts";
import { Alert, Loader, Typography } from "../../ui";
import ConfirmationsView from "./views/ConfirmationsView";
import NoAcceptedConfirmationsYetView from "./views/NoAcceptedConfirmationsYetView";

// IMPORT VIEWS
import NoConfirmationsView from "./views/NoConfirmationsView";
import NoOffersRequestView from "./views/NoOffersRequestView";
import SignedRequestView from "./views/SignedRequestView";
import WaitingActionRequestView from "./views/WaitingActionRequestView";
import WaitingInfoRequestView from "./views/WaitingInfoRequestView";

class RequestPage extends React.Component {
  state = {
    isLoading: true,
    request: {},
    error: false,
  };

  componentDidMount() {
    this.getRequest();
  }

  getRequest = async () => {
    const { id } = this.props.match.params;

    return RequestApi.getRequest(id)
      .then((request) => {
        this.setState({ request });
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() =>
        this.setState({
          isLoading: false,
        })
      );
  };

  render() {
    if (this.state.isLoading) {
      return (
        <DashboardLayout title={false}>
          <div className="text-center my-5 ">
            <Loader />
          </div>
        </DashboardLayout>
      );
    }

    if (this.state.error || !this.state.request) {
      return <Redirect to="/" />;
    }

    const { request } = this.state;
    const { id } = this.props.match.params;
    const { status } = request;

    if (status === "unconfirmed") {
      return <Redirect to={"/identity/confirmation/" + id} />;
    }

    let View = NoConfirmationsView;
    const { creditConfirmations } = request;

    if (creditConfirmations.length === 0) {
      View = NoConfirmationsView;
    } else {
      if (
        creditConfirmations.filter(
          (confirmation) => confirmation.type === "accept"
        ).length === 0
      ) {
        // 1. Offers exist, but none of them are accepted.
        View = NoAcceptedConfirmationsYetView;
      } else {
        // 2. Mixed bag of offers of all kind
        View = ConfirmationsView;
      }
    }

    if (status === "signed") {
      View = SignedRequestView;
    }

    if (
      status === "waiting_call" ||
      status === "waiting_sign" ||
      status === "canceled"
    ) {
      View = WaitingActionRequestView;
    }

    if (status === "no_offers") {
      View = NoOffersRequestView;
    }

    if (["waiting_info", "waiting_partner_info"].includes(status)) {
      View = WaitingInfoRequestView;
    }

    let documentsToUpload = [];

    if (status === "waiting_offer") {
      let lackingInfoCreditConfirmations = request.creditConfirmations.filter(
        (confirmation) => {
          return confirmation.type === "lacking-info";
        }
      );

      let { requiredDocuments } = this.context.user;

      for (const confirmation of lackingInfoCreditConfirmations) {
        const r = confirmation.requiredDocuments || [];

        for (const requiredDocument of requiredDocuments) {
          const { type } = requiredDocument;
          const files = requiredDocument.files || [];

          // not yet uploaded
          if (!files.length && r.includes(type)) {
            documentsToUpload.push(type);
          }
        }
      }
    }

    return (
      <DashboardLayout
        title={
          <>
            <Typography.Header1
              inline
              greyDark
              reduceSizeMobile
              className="mt-0"
            >
              Paraiška
            </Typography.Header1>
            <Typography.Header1 inline reduceSizeMobile className="pl-2 mt-0">
              {this.state.request.id}
            </Typography.Header1>
          </>
        }
      >
        {documentsToUpload.length !== 0 && (
          <Alert warning>
            Trūkstami dokumentai: {documentsToUpload.join(", ").toLowerCase()}.{" "}
            <Link to="/documents">
              <Typography.InlineText weight={500}>
                Įkelti dokumentus
              </Typography.InlineText>
            </Link>
          </Alert>
        )}

        <View request={this.state.request} getRequest={this.getRequest} />
      </DashboardLayout>
    );
  }
}

RequestPage.contextType = UserContext;
export default RequestPage;
