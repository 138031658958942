import moment from "moment";

const validatePersonalId = (personalId) => {
  // source: https://lt.wikipedia.org/wiki/Asmens_kodas

  if (!personalId) {
    return true;
  }

  personalId = String(personalId);

  if (personalId.length !== 11) {
    return false;
  }

  const centuries = {
    1: 1800,
    2: 1800,
    3: 1900,
    4: 1900,
    5: 2000,
    6: 2000,
  };

  const centuryLetter = personalId[0];
  const year = parseInt(personalId[1] + personalId[2]);
  const month = personalId[3] + personalId[4];
  const day = personalId[5] + personalId[6];

  if (!centuries[centuryLetter]) {
    return false;
  }

  const birthYear = centuries[centuryLetter] + year;

  const age = moment().diff(`${birthYear}-${month}-${day}`, "years", false);

  if (isNaN(age)) {
    return false;
  }

  if (age < 18) {
    return false;
  }

  return true;
};

export default validatePersonalId;
