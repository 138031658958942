import React from "react";
import { Typography } from "../../../ui";
import Offer from "../components/Offer";
import RequestHeader from "../components/RequestHeader";
import "./confirmationsView.css";


const ConfirmationsView = ({ request }) => {
  const { availableConfirmations, hideLabels } = request;
  let bvkkmnList = [];
  let companyList = [];
  
  for(let confirmation of availableConfirmations) {
    //console.log(confirmation)
    if(confirmation.creditConfirmation !== undefined && confirmation.creditConfirmation.type === "accept") {
      //console.log(confirmation.creditConfirmation);
      companyList.push(confirmation);
      bvkkmnList.push({creditCompany : confirmation.creditConfirmation.creditCompanyName, bvkkmn : confirmation.creditConfirmation.BVKKMN});
    }
  }
  // console.log(companyList)
  let byBvkkmn = bvkkmnList.slice(0);
  byBvkkmn.sort(function (a, b) {
    return a.bvkkmn - b.bvkkmn;
  });
  let creditCompanyList = [];
  byBvkkmn.forEach(function(v, i) {
    for(let creditCompanyConf of availableConfirmations) {
      if(creditCompanyConf.name === v.creditCompany) { 
        if(v.creditCompany === "Paskolų klubas" && request.companyVisibility === true) {
            creditCompanyList.push(creditCompanyConf);
        } else if(v.creditCompany !== "Paskolų klubas") creditCompanyList.push(creditCompanyConf);
        else if (request.companyVisibility === null || request.companyVisibility === undefined) creditCompanyList.push(creditCompanyConf)
      }
    }
  });
  // console.log(creditCompanyList);
  // console.log(byBvkkmn[0].creditCompany);

  const acceptedCompanies = availableConfirmations.filter((creditCompany) => {
    const { creditConfirmation } = creditCompany;

    return creditConfirmation && creditConfirmation.type === "accept";
  });

  const otherCompanies = availableConfirmations.filter((creditCompany) => {
    const { creditConfirmation } = creditCompany;

    if (!creditCompany.visible) {
      return false;
    }

    if (creditConfirmation && creditConfirmation.type === "accept") {
      return false;
    }

    return true;
  });

  return (
    <>
      <RequestHeader request={request} />

      <div className="mt-5">
        <Typography.Text size="lead" grey>
          Kreditorių pasiūlymai
        </Typography.Text>
      </div>
      
      <div className="mt-1">
        {creditCompanyList.map((creditCompany, index) => ( 
          
          <div className={`my-3 ${creditCompany.name === byBvkkmn[0].creditCompany && byBvkkmn.length> 1 ? "test": ""} mid` } key={index}>
            {creditCompany.name === byBvkkmn[0].creditCompany && byBvkkmn.length > 1 ? <button className="button recommendation">Rekomenduojama</button>: ""}
            <Offer creditCompany={creditCompany} hideLabel={hideLabels} />
          </div>
        ))}
      </div>
        
      <div className="mt-5">
        <Typography.Text size="lead" grey>
          Kiti kreditoriai
        </Typography.Text>
      </div>

      <div className="mt-1">
        {otherCompanies.map((creditCompany, index) => (
          <div className="my-3" key={index}>
            <Offer creditCompany={creditCompany} hideLabel={hideLabels} />
          </div>
        ))}
      </div>
    </>
  );
};

export default ConfirmationsView;
