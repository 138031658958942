import React from "react";
import getRequestStatus from "../../helpers/getRequestStatus";
import translations from "../../locale/lt_LT/request";
import { Badge } from "../../ui";

const RequestStatusBadge = ({ request }) => {
  // const { status } = request;
  const status = getRequestStatus(request);
  let badgeType = {};

  // canceled: "Nepavyko susisiekti",
  // unconfirmed: "Patvirtinkite tapatybę",
  // waiting_info: "Laukia informacijos",
  // waiting_partner_info: "Laukia sutuoktinio informacijos",
  // waiting_offer: "Laukia pasiūlymo",
  // waiting_call: "Laukia skambučio",
  // waiting_sign: "Laukia pasirašymo",
  // signed: "Pasirašyta",
  // inactive: "Neaktyvi",
  // unavailable: "Nematoma",

  switch (status) {
    case "canceled":
      badgeType = {
        danger: true,
      };
      break;

    case "has_offer":
      badgeType = {
        primary: true,
      };
      break;

    case "no_offers":
      badgeType = {
        danger: true,
      };
      break;

    case "unconfirmed":
      badgeType = {
        danger: true,
      };
      break;

    case "waiting_info": {
      badgeType = {
        primary: true,
      };
      break;
    }

    case "waiting_partner_info": {
      badgeType = {
        primary: true,
      };
      break;
    }

    case "waiting_call": {
      badgeType = {
        warning: true,
      };
      break;
    }

    case "waiting_sign": {
      badgeType = {
        primary: true,
      };
      break;
    }

    case "signed": {
      badgeType = {
        success: true,
      };
      break;
    }

    default:
      badgeType = {};
      break;
  }

  return (
    <Badge
      style={{
        marginTop: "-3px",
      }}
      {...badgeType}
    >
      {translations.status[status]}
    </Badge>
  );
};

export default RequestStatusBadge;
