import React from "react";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { DashboardLayout } from "../../layouts";
import { Alert, Typography } from "../../ui";
import { UserContext } from "../../context/UserContext";
import NoUploadedDocuments from "./components/NoUploadedDocuments";
import UploadedDocument from "./components/UploadedDocument";
import DocumentUploadForm from "./components/DocumentUploadForm";

class DocumentsPage extends React.Component {
  async componentDidMount() {
    await this.context.getUser();
  }

  render() {
    const { requiredDocuments } = this.context.user;

    const uploadedDocuments = requiredDocuments.filter(
      (document) => document.files && document.files.length > 0
    );

    const documentsToUpload = requiredDocuments.filter(
      (document) => !document.files || document.files.length === 0
    );

    const documentsToUploadByType = documentsToUpload.map(
      (document) => document.type
    );

    return (
      <DashboardLayout
        title={
          <>
            <Typography.Header1 reduceSizeMobile className="mt-0">
              Dokumentai
            </Typography.Header1>
          </>
        }
        activeRoute="documents"
      >
        {documentsToUpload.length > 0 && (
          <Alert warning>
            Trūkstami dokumentai:{" "}
            {documentsToUploadByType.join(", ").toLowerCase()}.
          </Alert>
        )}

        <ScreenClassRender
          render={(screenClass) => (
            <Row
              {...(["xs", "sm", "md"].includes(screenClass)
                ? {
                    gutterWidth: 0,
                  }
                : {})}
            >
              <Col xs={12} sm={12} lg={5}>
                <DocumentUploadForm documentsToUpload={documentsToUpload} />
              </Col>
              <Col
                xs={12}
                sm={12}
                lg={7}
                className={uploadedDocuments.length === 0 ? "my-auto" : null}
              >
                {uploadedDocuments.length === 0 ? (
                  <NoUploadedDocuments />
                ) : (
                  <>
                    {uploadedDocuments.map((document, index) => (
                      <UploadedDocument
                        document={document}
                        key={index}
                        {...(["xs", "sm", "md"].includes(screenClass)
                          ? {
                              className: "mt-3",
                            }
                          : {})}
                      />
                    ))}
                  </>
                )}
              </Col>
            </Row>
          )}
        />
      </DashboardLayout>
    );
  }
}

DocumentsPage.contextType = UserContext;
export default DocumentsPage;
