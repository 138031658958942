import axios from "axios";
import errors from "../locale/lt_LT/errors";

const UserApi = {
  login: async (email, password) => {
    try {
      var response = await axios.post(
        process.env.REACT_APP_API + "/api/users/login",
        {
          email,
          password,
        }
      );
    } catch (error) {
      const e = errors[error.response.data.error] || errors.default;

      return Promise.reject(e);
    }

    if (!response || !response.data || !response.data.token) {
      return Promise.reject(errors.default);
    }

    const { data } = response;
    if (data.error) {
      const error = errors[data.error] || errors.default;

      return Promise.reject(error);
    }

    return data.token;
  },
  register: async ({
    firstName,
    lastName,
    email,
    password,
    contactNumber,
    marketingAccept,
    facebookId,
    googleId,
  }) => {
    if (!firstName || !lastName || !email || !password || !contactNumber) {
      return Promise.reject();
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "/api/users",
        {
          firstName,
          lastName,
          email,
          password,
          contactNumber,
          marketingAccept,
          facebookId,
          googleId,
        }
      );

      var user = response.data;
    } catch (error) {
      return Promise.reject(
        errors[error.response.data.error] || errors.default
      );
    }

    return user;
  },
  getUser: async () => {
    const accessToken = localStorage.getItem("token");

    if (!accessToken) {
      return;
    }

    try {
      const tokenValidationResponse = await axios.get(
        process.env.REACT_APP_API + "/api/accessTokens/" + accessToken
      );

      const userId = tokenValidationResponse.data.data;

      var userResponse = await axios.get(
        process.env.REACT_APP_API + "/api/users/" + userId,
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
    } catch (error) {
      return Promise.reject(errors.default);
    }

    if (!userResponse || !userResponse.data) {
      return Promise.reject(errors.default);
    }

    const user = userResponse.data;

    if (user.role !== "user") {
      localStorage.removeItem("token");
      window.location.href = "https://partneriai.creditking.lt/";
    }

    return user;
  },
  patchUser: async (userId, user) => {
    const accessToken = localStorage.getItem("token");

    if (!accessToken) {
      return;
    }

    try {
      await axios.patch(
        process.env.REACT_APP_API + "/api/users/" + userId,
        { ...user },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
    } catch (error) {
      return Promise.reject(
        errors[error.response.data.error] || errors.default
      );
    }

    return Promise.resolve();
  },
  postResetToken: async (email) => {
    if (!email) {
      return Promise.reject(errors.default);
    }

    try {
      await axios.post(process.env.REACT_APP_API + "/api/resetTokens", {
        email,
      });
    } catch (error) {
      return Promise.reject(
        errors[error.response.data.error] || errors.default
      );
    }

    return Promise.resolve();
  },
  validateResetToken: async (token) => {
    if (!token) {
      return Promise.reject(errors.default);
    }

    try {
      var response = await axios.get(
        process.env.REACT_APP_API + "/api/resetTokens/" + token
      );
    } catch (error) {
      return Promise.reject(
        errors[error.response.data.error] || errors.default
      );
    }

    return response.data;
  },
  resetPassword: async (userId, token, password) => {
    if (!userId || !token || !password) {
      return Promise.reject(errors.default);
    }

    try {
      await axios.patch(
        process.env.REACT_APP_API + "/api/users/" + userId + "/reset",
        {
          token,
          password,
        }
      );
    } catch (error) {
      return Promise.reject(
        errors[error.response.data.error] || errors.default
      );
    }

    return Promise.resolve();
  },
  postLatestSubmissionState: async (userId, state = {}) => {
    const accessToken = localStorage.getItem("token");

    if (!userId || !accessToken) {
      return Promise.reject(errors.default);
    }

    try {
      await axios.post(
        process.env.REACT_APP_API +
          "/api/users/" +
          userId +
          "/latestSubmissionState",
        {
          state,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
    } catch (error) {
      return Promise.reject(
        errors[error.response.data.error] || errors.default
      );
    }

    return Promise.resolve();
  },
  postRequiredDocument: async (userId, type, files = []) => {
    const accessToken = localStorage.getItem("token");

    if (!userId || !type || !files || files.length === 0 || !accessToken) {
      return Promise.reject(errors.default);
    }

    try {
      await axios.post(
        process.env.REACT_APP_API +
          "/api/users/" +
          userId +
          "/requiredDocuments",
        {
          type,
          files,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
    } catch (error) {
      return Promise.reject(
        errors[error.response.data.error] || errors.default
      );
    }

    return Promise.resolve();
  },
  deleteRequiredMethod: async (userId, type) => {
    const accessToken = localStorage.getItem("token");

    if (!userId || !type || !accessToken) {
      return Promise.reject(errors.default);
    }

    try {
      await axios.request({
        headers: {
          Authorization: accessToken,
        },
        method: "DELETE",
        url:
          process.env.REACT_APP_API +
          "/api/users/" +
          userId +
          "/requiredDocuments",
        data: {
          type,
        },
      });
    } catch (error) {
      return Promise.reject(
        errors[error.response.data.error] || errors.default
      );
    }

    return Promise.resolve();
  },
};

export default UserApi;
