import React from "react";
import { toast } from "react-toastify";
import { UserApi } from "../../../api";
import { UserContext } from "../../../context/UserContext";
import { Button, Dialog, PaddingWrapper } from "../../../ui";

const DocumentDeleteDialog = ({ isOpen, onClose, document }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const userContext = React.useContext(UserContext);

  const handleDeleteDocument = async () => {
    setIsLoading(true);

    try {
      await UserApi.deleteRequiredMethod(userContext.user._id, document.type);
    } catch (error) {
      toast.error(error);
      return setIsLoading(false);
    }

    toast.dark("Dokumentas sėkmingai ištrintas");
    await userContext.getUser();
    setIsLoading(false);
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={"Ar tikrai norite ištrinti dokumentus?"}
      onClose={onClose}
    >
      <PaddingWrapper>
        <Button disabled={isLoading} onClick={handleDeleteDocument} fullWidth>
          Patvirtinti ir ištrinti
        </Button>

        <Button link fullWidth onClick={onClose} className="mt-2">
          Atšaukti
        </Button>
      </PaddingWrapper>
    </Dialog>
  );
};

export default DocumentDeleteDialog;
