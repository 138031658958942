import React from "react";
import { Row, Col, Container, Visible } from "react-grid-system";
import { Navbar } from "../../components";
import SideViewWrapper from "./components/SideViewWrapper";
import SideViewContent from "./components/SideViewContent";

// Sideviews
import Secure from "./components/sideviews/Secure";
import Free from "./components/sideviews/Free";
import Fast from "./components/sideviews/Fast";

const sideViewBreakPoints = {
  sm: 4,
};

const AuthenticationLayout = ({
  children,
  page,
  sideView = "secure",
  hideSettings = false,
  showMyRequests = false,
}) => {
  const sideViews = {
    secure: Secure,
    free: Free,
    fast: Fast,
  };

  const SideView = sideViews[sideView] || Secure;

  return (
    <Row gutterWidth={0} className="route-fade-in">
      <Col className="pb-5">
        <Navbar
          page={page}
          hideSettings={hideSettings}
          showMyRequests={showMyRequests}
        />
        <Container>{children}</Container>
      </Col>
      <Visible lg xl xxl>
        <Col {...sideViewBreakPoints}>
          <SideViewWrapper>
            <SideViewContent>
              <SideView />
            </SideViewContent>
          </SideViewWrapper>
        </Col>
      </Visible>
    </Row>
  );
};

export default AuthenticationLayout;
