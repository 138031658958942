import React from "react";
import styled, { css } from "styled-components";
import { Typography } from "../";

const Wrapper = styled.div`
  position: relative;
  padding: 5px 0px;
  background: #f8f8f8;
  border-radius: 8px;
  border: none;
  padding: 15px 20px;
  border: 1px solid #f8f8f8;
  overflow: hidden;

  :focus-within {
    border: 1px solid #94b1ff;
  }

  :after {
    content: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L6.70711 6.70711C6.31658 7.09763 5.68342 7.09763 5.29289 6.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' fill='%23222222'/%3E%3C/svg%3E%0A");
    color: black;
    position: absolute;
    right: 0px;
    padding-left: 3px;
    padding-right: 15px;
    background: #f8f8f8;
    height: 40px;
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid #d02b2b !important;
    `}
`;

const SelectBox = styled.select`
  font-size: 16px;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  background: none !important;
  border: none !important;
  padding: 1px 0px;

  /* disable default dropdown chevron */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  ::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }

  ${({ label }) =>
    label &&
    css`
      position: relative;
      bottom: -5px;
    `}
`;

const FloatingLabelStyle = css`
  top: 5px;
  left: 20px;
  font-size: 12px;
  opacity: 0.9;
`;

const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 15px;
  transition: 0.3s ease all;
  color: #aaacb6;
  white-space: nowrap;

  ${SelectBox}:focus ~ & {
    ${FloatingLabelStyle}
  }

  /* display floating label if value exists */
  ${({ value }) =>
    value &&
    css`
      ${FloatingLabelStyle}
    `}
`;

const Select = (props) => {
  const selectBox = React.useRef();

  return (
    <>
      <Wrapper
        error={props.error}
        onClick={() => {
          if (selectBox.current) {
            selectBox.current.focus();
          }
        }}
      >
        <SelectBox {...props} ref={selectBox}>
          {props.withBlankSelect && (
            <option value="" selected disabled></option>
          )}
          {props.children}
        </SelectBox>

        {props.label && <Label value={props.value}>{props.label}</Label>}
      </Wrapper>

      {props.helperText && (
        <Typography.Text grey size="small" weight={500} className="mb-0 mt-2">
          {props.helperText}
        </Typography.Text>
      )}

      {props.errorText && (
        <Typography.Text size="small" weight={500} red className="mb-0 mt-2">
          {props.errorText}
        </Typography.Text>
      )}
    </>
  );
};

export default Select;
