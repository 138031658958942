import axios from "axios";
import errors from "../locale/lt_LT/errors";

const OfferApi = {
  acceptOffer: async (creditConfirmationId) => {
    try {
      await axios.patch(
        process.env.REACT_APP_API +
          "/api/creditConfirmations/" +
          creditConfirmationId +
          "/accept",
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    } catch (error) {
      const message = error.response.data.error;
      return Promise.reject(errors[message] || errors.default);
    }

    return {
      success: true,
    };
  },
};

export default OfferApi;
