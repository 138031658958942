import React from "react";
import { Col, Row } from "react-grid-system";
import { CreditCompanyLogo } from "..";
import OfferStatusBadge from "../../pages/RequestPage/components/OfferStatusBadge";
import { Card, Typography } from "../../ui";
import "./CompanyListAnimation.css";

const CompanyListAnimation = () => {
  // Hardcoded companies list
  const companies = [
    {
      name: "Citadele",
      slug: "citadele",
    },
    {
      name: "Vivus",
      slug: "vivus",
    },
    {
      name: "SAVY",
      slug: "savy",
    },
    {
      name: "Paskolų klubas",
      slug: "paskolu-klubas",
    },
    {
      name: "Paskola 23",
      slug: "paskola-23",
    },
    {
      name: "Giantus",
      slug: "first-finance",
    },
    {
      name: "Mogo",
      slug: "mogo",
    },
    {
      name: "mano.bank",
      slug: "manobank",
    },
    // {
    //   name: "Kredito unija TAUPA",
    //   slug: "taupa",
    // },
  ];

  return (
    <div className="wrapper">
      <div className="carousel">
        {companies.map((creditCompany) => (
          <div className="carousel__item">
            <Card className="w-100 carousel__card">
              <div className="carousel__card__content">
                <Row gutterWidth={0}>
                  <Col xs={9} className="my-auto">
                    <Typography.Header2 reduceSizeMobile inline>
                      {creditCompany.name}
                    </Typography.Header2>
                    {""}
                    <OfferStatusBadge status="waiting_offer" />
                  </Col>
                  <Col xs={3} className="my-auto text-right">
                    <CreditCompanyLogo creditCompany={creditCompany} />
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyListAnimation;
