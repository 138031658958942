import React from "react";
import { Row, Col } from "react-grid-system";
import { AuthenticationLayout } from "../../layouts";
import { Button, Input, PaddingWrapper, Typography } from "../../ui";
import { Formik, Field, Form } from "formik";
import errors from "../../locale/lt_LT/errors";
import * as yup from "yup";
import { UserApi } from "../../api";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  password: yup.string(errors.invalidFormat).required(errors.required),
});

const ResetPage = ({ match }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();

  const { token } = match.params;

  if (!token) {
    history.push("/");
  }

  const handleSubmit = async (values) => {
    setIsLoading(true);

    try {
      var response = await UserApi.validateResetToken(token);
    } catch (error) {
      toast.error(error);
      return history.push("/");
    }

    const userId = response.data;

    try {
      await UserApi.resetPassword(userId, token, values.password);
    } catch (error) {
      setIsLoading(false);
      return toast.error(error);
    }

    history.push("/");
  };

  return (
    <AuthenticationLayout>
      <Row gutterWidth={0}>
        <Col xs={12} sm={12} md={10} lg={8} xl={7} xxl={6} className="mx-auto">
          <Typography.Header1 reduceSizeMobile>
            Sukurkite naują slaptažodį
          </Typography.Header1>

          <Formik
            initialValues={{
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <PaddingWrapper className="mt-0">
                  <Field
                    label="Naujas slaptažodis"
                    id="password"
                    name="password"
                    type="password"
                    as={Input}
                    fullWidth
                    error={errors.password && touched.password}
                    errorText={touched.password && errors.password}
                    required
                  />
                </PaddingWrapper>

                <PaddingWrapper>
                  <Button type="submit" disabled={isLoading} fullWidth>
                    Išsaugoti
                  </Button>
                </PaddingWrapper>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </AuthenticationLayout>
  );
};

export default ResetPage;
