import React from "react";
import { Col, Row, Visible } from "react-grid-system";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { RequestApi } from "../../api";
import { UserContext } from "../../context/UserContext";
import { DashboardLayout } from "../../layouts";
import { Loader, Typography } from "../../ui";
import Request from "./components/Request";

class RequestListPage extends React.Component {
  state = {
    isLoading: true,
    requests: [],
  };

  componentDidMount() {
    RequestApi.getRequests()
      .then((requests) => {
        this.setState({
          requests,
        });
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 500);
      });
  }

  render() {
    const { creditRequests } = this.context.user;

    if (!creditRequests || creditRequests.length === 0) {
      return <Redirect to="/submit" />;
    }

    if (creditRequests.length === 1) {
      return <Redirect to={"/requests/" + creditRequests[0]} />;
    }

    if (this.state.isLoading) {
      return (
        <DashboardLayout title={false}>
          <div className="text-center my-5 ">
            <Loader />
          </div>
        </DashboardLayout>
      );
    }

    const nameToVocative = (name = "") => {
      name = name.toLocaleLowerCase();

      const lastTwoLetters = name.slice(-2);

      if (lastTwoLetters[1] === "a") {
        name = name.slice(0, -1) + "a";
      } else if (lastTwoLetters[1] === "ė") {
        name = name.slice(0, -1) + "e";
      } else if (lastTwoLetters === "as") {
        name = name.slice(0, -2) + "ai";
      } else if (lastTwoLetters === "is") {
        name = name.slice(0, -2) + "i";
      } else if (lastTwoLetters === "us") {
        name = name.slice(0, -2) + "au";
      } else if (lastTwoLetters === "ys") {
        name = name.slice(0, -2) + "y";
      }

      return name.charAt(0).toUpperCase() + name.slice(1);
    };

    return (
      <DashboardLayout
        title={
          <>
            <Typography.Header1
              inline
              greyDark
              className="mt-0"
              reduceSizeMobile
            >
              Sveiki sugrįžę,
            </Typography.Header1>
            <Typography.Header1 inline className="pl-2 mt-0" reduceSizeMobile>
              {nameToVocative(this.context.user.firstName)}
            </Typography.Header1>
          </>
        }
      >
        <Typography.Text className="mt-lg-5" size="lead" weight={500} greyDark>
          Mano paraiškos
        </Typography.Text>

        <Visible lg xl xxl>
          <Row className="text-center mt-3 px-4" gutterWidth={5}>
            <Col sm={1} className="text-left">
              <Typography.InlineText greyDark size="micro">
                KODAS
              </Typography.InlineText>
            </Col>
            <Col>
              <Typography.InlineText greyDark size="micro">
                SUKŪRIMO DATA
              </Typography.InlineText>
            </Col>
            <Col>
              <Typography.InlineText greyDark size="micro">
                TIPAS
              </Typography.InlineText>
            </Col>
            <Col>
              <Typography.InlineText greyDark size="micro">
                SUMA
              </Typography.InlineText>
            </Col>
            <Col>
              <Typography.InlineText greyDark size="micro">
                TERMINAS
              </Typography.InlineText>
            </Col>
            <Col className="text-right">
              <Typography.InlineText greyDark size="micro">
                STATUSAS
              </Typography.InlineText>
            </Col>
          </Row>
        </Visible>

        <div className="mt-2">
          {this.state.requests.map((request, index) => (
            <Request request={request} key={index} />
          ))}
        </div>
      </DashboardLayout>
    );
  }
}

RequestListPage.contextType = UserContext;
export default RequestListPage;
