import styled from "styled-components";

const SideViewWrapper = styled.aside`
  background-color: #305dd3;
  height: 100%;
  color: white;
  width: 100%;
  min-height: 100vh;
`;

export default SideViewWrapper;
