import React from "react";
import { Typography } from "../../../ui";
import { ReactComponent as NofilesLogo } from "../../../assets/img/no-files.svg";

const NoUploadedDocuments = () => (
  <div className="text-center">
    <div>
      <NofilesLogo />
    </div>

    <Typography.Text grey className="mt-1">
      Kol kas Jūs nesate
      <br />
      įkėlęs jokių dokumentų
    </Typography.Text>
  </div>
);

export default NoUploadedDocuments;
