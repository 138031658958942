import { css } from "styled-components";
import theme from "../../config/theme";

const paddingSwitcher = (margin = "medium") => {
  const pixels = {
    tiny: theme.spacing.marginBase,
    small: theme.spacing.marginBase * 2,
    medium: theme.spacing.marginBase * 4,
    large: theme.spacing.marginBase * 5,
    extraLarge: theme.spacing.marginBase * 6,
  };

  return css`
    padding-top: ${pixels[margin] / 2}px;
    padding-bottom: ${pixels[margin] / 2}px;
  `;
};

export default paddingSwitcher;
