import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    html,
    body {
      margin: 0;
      height: 100%;
    }

    body {
      color: ${({ theme }) => theme.colors.black};
      background: ${({ theme }) => theme.colors.white};
      font-family: ${({ theme }) => theme.fonts.family};
      font-size: ${({ theme }) => theme.fonts.size}px;
    }

    button,
    input,
    select,
    textarea {
      font-family: ${({ theme }) => theme.fonts.family};
    }

    .switch-wrapper {
      position: relative;
    }
    
    .switch-wrapper > div {
      position: absolute;
      width: 100vw;
    }

    .h-100 {
      height: 100%;
    }

    .w-100 {
      width: 100%;
    }

    .popup-content {
      border: none !important;
      border-radius: 8px !important;
      box-shadow: 0px 2px 8px rgba(0,0,0,0.08) !important;
    }

    .Toastify__toast-container {
      /* max-width: 300px; */
      /* width: 100%; */
    }

    .Toastify__toast {
      border-radius: 8px;
      padding: 5px 20px;
      min-height: 40px;
      box-shadow: 0px 4px 16px rgba(34, 34, 34, 0.3);
      font-family: ${({ theme }) => theme.fonts.family};
      font-size: 14px;
    }

    .Toastify__close-button {
      margin-top: auto;
      margin-bottom: auto;

      svg {
        vertical-align: -3px;
      }
    }

    .ReactModal__Overlay {
      opacity: 0;
      transition: all 200ms ease-in-out;
    }

    .ReactModal__Overlay--after-open {
      opacity: 1;
    }

    .ReactModal__Overlay--before-close {
      opacity: 0;
    }

    .fade-in {
      -webkit-animation: fade-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
              animation: fade-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }

    @media (max-width: 1199px) {
      .route-fade-in {
        -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      }
    }

    /**
     * ----------------------------------------
     * animation fade-in
     * ----------------------------------------
     */
    @-webkit-keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
`;

export default GlobalStyles;
