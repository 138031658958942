import React from "react";
import { Col, Row } from "react-grid-system";
import { Card } from "../../../ui";
import RequestHeader from "../components/RequestHeader";
import CarExchangeSubview from "./subviews/CarExchangeSubview";
import AddPartnerSubview from "./subviews/AddPartnerSubview";
import RealEstateExchangeSubview from "./subviews/RealEstateExchangeSubview";

const WaitingInfoRequestView = ({ request, getRequest }) => {
  let Subview;

  if (request.status === "waiting_info" && request.creditType === "car") {
    Subview = CarExchangeSubview;
  } else if (request.status === "waiting_partner_info") {
    Subview = AddPartnerSubview;
  } else if (
    request.status === "waiting_info" &&
    request.creditType === "spending-real-estate-exchange"
  ) {
    Subview = RealEstateExchangeSubview;
  }

  return (
    <>
      <RequestHeader request={request} />

      <Row gutterWidth={0}>
        <Col xs={12} sm={12} md={12} lg={8} xl={7} xxl={6}>
          <Card className="py-1 pb-3 px-3 px-sm-4 mt-3">
            <div className="px-0 px-sm-0 px-md-3">
              <Subview request={request} getRequest={getRequest} />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default WaitingInfoRequestView;
