import React from "react";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { Card, Typography, Href } from "../../../ui";
import OfferHeader from "../components/OfferHeader";
import translations from "../../../locale/lt_LT/request";
import { ReactComponent as HandLogo } from "../../../assets/img/hand.svg";
import { ReactComponent as LeftArrowLogo } from "../../../assets/img/left-arrow.svg";
import Offer from "../components/Offer";

const WaitingActionRequestView = ({ request }) => {
  const { acceptedOffer } = request;
  const [showOtherOffers, setShowOtherOffers] = React.useState(false);

  const otherAvailableOffers = request.availableConfirmations.filter(
    (creditCompany) => {
      const { creditConfirmation } = creditCompany;

      return (
        creditConfirmation &&
        creditConfirmation.type === "accept" &&
        !creditConfirmation.offerAccepted
      );
    }
  );

  return (
    <>
      <OfferHeader creditConfirmation={acceptedOffer} request={request} />

      <div className="mt-4">
        <ScreenClassRender
          render={(screenClass) => (
            <Row
              {...(["xs", "sm", "md"].includes(screenClass)
                ? {
                    gutterWidth: 0,
                  }
                : {})}
            >
              <Col xs={12} sm={12} md={12} lg={8}>
                <Card className="h-100">
                  <div className="p-4">
                    <Row>
                      <Col xs={12}>
                        <div style={{ display: "inline-block" }} class="mr-3">
                          <HandLogo
                            style={{
                              marginBottom: "-5px",
                            }}
                          />
                        </div>

                        <div style={{ display: "inline-block" }}>
                          <Typography.InlineText greyDark size="micro">
                            TIPAS
                          </Typography.InlineText>

                          <div>
                            <Typography.Text
                              size="lead"
                              weight={500}
                              className="m-0"
                            >
                              {
                                translations.creditType[
                                  request.creditType === "spending" &&
                                  request.subCreditType
                                    ? request.subCreditType
                                    : request.creditType
                                ]
                              }
                            </Typography.Text>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col xs={12} sm={12} md={12} lg={6}>
                        <Typography.InlineText greyDark size="micro">
                          PASIŪLYMO SUMA
                          {acceptedOffer.creditOfferFrom !==
                            acceptedOffer.creditOfferTo && " - MAKSIMALI SUMA"}
                        </Typography.InlineText>

                        <div>
                          <Typography.Header1 reduceSizeMobile className="m-0">
                            {acceptedOffer.creditOfferFrom} €{" "}
                            {acceptedOffer.creditOfferFrom !==
                              acceptedOffer.creditOfferTo &&
                              ` - ${acceptedOffer.creditOfferTo} €`}
                          </Typography.Header1>
                        </div>
                      </Col>

                      <Col className="mt-3 mt-md-0">
                        <Typography.InlineText greyDark size="micro">
                          MĖN. ĮMOKA
                        </Typography.InlineText>

                        <div>
                          <Typography.Header1 reduceSizeMobile className="m-0">
                            {acceptedOffer.monthlyPayment} €
                          </Typography.Header1>
                        </div>
                      </Col>

                      <Col className="mt-3 mt-md-0">
                        <Typography.InlineText greyDark size="micro">
                          TERMINAS
                        </Typography.InlineText>

                        <div>
                          <Typography.Header1
                            reduceSizeMobile
                            inline
                            className="m-0"
                          >
                            {acceptedOffer.creditLength}
                          </Typography.Header1>{" "}
                          <Typography.InlineText weight={500} size="micro">
                            mėn.
                          </Typography.InlineText>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>

              <Col xs={12} sm={12} md={12} lg={4} className="mt-4 mt-md-0">
                <Card className="h-100">
                  <div className="p-4">
                    <Row>
                      <Col>
                        <Typography.InlineText greyDark size="micro">
                          PALŪKANOS
                        </Typography.InlineText>
                      </Col>
                      <Col className="text-right">
                        <Typography.InlineText weight={500} size="lead">
                          {acceptedOffer.interestRate} %
                        </Typography.InlineText>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <Typography.InlineText greyDark size="micro">
                          BVKKMN
                        </Typography.InlineText>
                      </Col>
                      <Col className="text-right">
                        <Typography.InlineText weight={500} size="lead">
                          {acceptedOffer.BVKKMN} %
                        </Typography.InlineText>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col xs={8}>
                        <Typography.InlineText greyDark size="micro">
                          SUTARTIES MOKESTIS
                        </Typography.InlineText>
                      </Col>
                      <Col className="text-right">
                        <Typography.InlineText weight={500} size="lead">
                          {acceptedOffer.fee || "0"} €
                        </Typography.InlineText>
                      </Col>
                    </Row>

                    {acceptedOffer.entranceFee ? (
                      <Row className="mt-3">
                        <Col>
                          <Typography.InlineText greyDark size="micro">
                            STOJIMO MOKESTIS
                          </Typography.InlineText>
                        </Col>
                        <Col className="text-right">
                          <Typography.InlineText weight={500} size="lead">
                            {acceptedOffer.entranceFee} €
                          </Typography.InlineText>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    {acceptedOffer.share ? (
                      <Row className="mt-3">
                        <Col>
                          <Typography.InlineText greyDark size="micro">
                            PAJINIS ĮNAŠAS
                          </Typography.InlineText>
                        </Col>
                        <Col className="text-right">
                          <Typography.InlineText weight={500} size="lead">
                            {acceptedOffer.share} €
                          </Typography.InlineText>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          )}
        />
      </div>

      {otherAvailableOffers && otherAvailableOffers.length > 0 && (
        <div className="mt-4">
          {showOtherOffers ? (
            <>
              <Typography.Text className="mt-5" greyDark>
                Kitų kreditorių pasiūlymai
              </Typography.Text>

              <div className="mt-1">
                {otherAvailableOffers.map((creditCompany, index) => (
                  <div className="my-3" key={index}>
                    <Offer creditCompany={creditCompany} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <Href href={null} onClick={() => setShowOtherOffers(true)}>
                <Typography.Text greyDark>
                  <LeftArrowLogo className="pl-1 px-2" /> Kitų kreditorių
                  pasiūlymai
                </Typography.Text>
              </Href>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default WaitingActionRequestView;
