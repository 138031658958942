import React from "react";
import { Col, Row, Visible } from "react-grid-system";
import { CreditCompanyLogo, RequestStatusBadge } from "../../../components";
import { Card, Typography } from "../../../ui";

const OfferHeader = ({ creditConfirmation, request }) => {
  return (
    <Card className="py-4 py-sm-2 px-4">
      <Row gutterWidth={0}>
        {/* DESKTOP / TABLET */}
        <Visible md lg xl xxl>
          <Col className="my-auto">
            <Typography.Header2 inline>
              {creditConfirmation.creditCompany.name}
            </Typography.Header2>{" "}
            <span
              style={{
                verticalAlign: "5px",
                marginLeft: "8px",
              }}
            >
              <RequestStatusBadge request={request} />
            </span>
          </Col>
          <Col className="text-right my-auto">
            <CreditCompanyLogo
              creditCompany={creditConfirmation.creditCompany}
            />
          </Col>
        </Visible>

        {/* MOBILE */}
        <Visible xs sm>
          <Col xs={12} sm={12} className="mb-2">
            <CreditCompanyLogo
              creditCompany={creditConfirmation.creditCompany}
            />
          </Col>
          <Col className="my-auto">
            <Typography.Header2 reduceSizeMobile className="my-0" inline>
              {creditConfirmation.creditCompany.name}
            </Typography.Header2>
          </Col>
          <Col className="my-auto text-right">
            <RequestStatusBadge request={request} />
          </Col>
        </Visible>
      </Row>
    </Card>
  );
};

export default OfferHeader;
