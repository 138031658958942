import React from "react";
import { FormRow, IdentityConfirmationMethods, RequestStepperHeader } from "../../components";
import Script from "react-load-script";
import { ApplicationLayout } from "../../layouts";
import { PaddingWrapper, Typography, Dialog, Button, Loader, Alert } from "../../ui";
import { IdentityApi, RequestApi } from "../../api";
import { Redirect } from "react-router-dom";
import { ReactComponent as IdentityLogo } from "../../assets/img/identity.svg";
import SmartidIdentityDialog from "./components/SmartidIdentityDialog";
import { toast } from "react-toastify";
import { Hidden } from "react-grid-system";

class IdentityConfirmationPage extends React.Component {
  state = {
    request: {},
    isBankDialogOpen: false,
    isSmartidIdentityDialogOpen: false,
    bank: null,
    isLoading: true,
    error: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    RequestApi.getRequest(id)
      .then((request) => {
        this.setState({ request });
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() =>
        this.setState({
          isLoading: false,
        })
      );
  }

  handleNeopay = async (requestConfirmationId) => {
    this.setState({
      isLoading: true,
    });

    try {
      const response = await IdentityApi.createNeopayToken(requestConfirmationId, this.state.bank);
      window.location.href = "https://psd2.neopay.lt/widget.html?" + response.token;

      return;
    } catch (error) {
      this.setState({ isLoading: false });
      return toast.error("Nepavyko inicijuoti pavedimo");
    }
  };

  render() {
    if (this.state.isLoading) {
      return (
        <ApplicationLayout>
          <div className="text-center my-5 ">
            <Loader />
          </div>
        </ApplicationLayout>
      );
    }

    if (this.state.error || !this.state.request) {
      return <Redirect to="/" />;
    }

    const { request } = this.state;

    if (request.status !== "unconfirmed") {
      return <Redirect to={"/requests/" + request._id} />;
    }

    const currentIdentityConfirmation =
      request.maritalStatus === "married" && request.withCoApplicant && request.personalConfirmation.completed
        ? request.partnerConfirmation
        : request.personalConfirmation;

    const currentStep = currentIdentityConfirmation.partner ? 5 : 4;
    const totalSteps = request.maritalStatus === "married" && request.withCoApplicant ? 5 : 4;

    const confirmationMethods = [
      {
        title: "Elektroninis parašas",
        description: "Tapatybė bus patvirtinta iš karto.",
        logo: "elektroninis",
        onClick: () => {
          window.location.href =
            process.env.REACT_APP_API + `/api/requestConfirmations/${currentIdentityConfirmation._id}/url`;
        },
      },
      {
        title: "Smart-ID",
        description:
          "Tapatybė bus patvirtinta iš karto. Reikalinga „Smart-ID“ paskyra (jeigu turite „Smart-ID Basic” paskyrą, šiuo būdu tapatybės patvirtinti negalėsite).",
        logo: "smartid",
        onClick: () => {
          this.setState({
            isSmartidIdentityDialogOpen: true,
          });
        },
      },
      {
        title: "Swedbank",
        description: "Tapatybės patvirtinimas užtruks iki 1 minutės.",
        logo: "swedbank",
        onClick: () => {
          this.setState({
            isBankDialogOpen: true,
            bank: "Swedbank",
            // isBankDialogOpen: true,
            // bank: null,
          });
        },
      },
      {
        title: "SEB",
        description: "Tapatybės patvirtinimas užtruks iki 1 minutės.",
        logo: "seb",
        onClick: () => {
          this.setState({
            isBankDialogOpen: true,
            bank: "Seb",
            // isBankDialogOpen: true,
            // bank: null,
          });
        },
      },
      {
        title: "Luminor DNB",
        description:
          "Tapatybės patvirtinimas užtruks iki 1 valandos. Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus patvirtinta.",
        logo: "luminor-dnb",
        onClick: () => {
          this.setState({
            isBankDialogOpen: true,
            bank: null,
          });
        },
      },
      {
        title: "Luminor Nordea",
        description:
          "Tapatybės patvirtinimas užtruks iki 1 valandos. Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus patvirtinta.",
        logo: "luminor-nordea",
        onClick: () => {
          this.setState({
            isBankDialogOpen: true,
            bank: null,
          });
        },
      },
      {
        title: "Citadele",
        description:
          "Tapatybės patvirtinimas užtruks iki 1 valandos. Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus patvirtinta.",
        logo: "citadele",
        onClick: () => {
          this.setState({
            isBankDialogOpen: true,
            bank: null,
          });
        },
      },
      {
        title: "Medicinos bankas",
        description:
          "Tapatybės patvirtinimas užtruks iki 1 valandos. Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus patvirtinta.",
        logo: "medicinos-bankas",
        onClick: () => {
          this.setState({
            isBankDialogOpen: true,
            bank: null,
          });
        },
      },
      {
        title: "Šiaulių bankas",
        description:
          "Tapatybės patvirtinimas užtruks iki 1 valandos. Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus patvirtinta.",
        logo: "siauliu-bankas",
        onClick: () => {
          this.setState({
            isBankDialogOpen: true,
            bank: null,
          });
        },
      },
      {
        title: "Paysera",
        description:
          "Tapatybės patvirtinimas užtruks iki 1 valandos. Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus asmens kodo, tapatybė nebus patvirtinta.",
        logo: "paysera",
        onClick: () => {
          this.setState({
            isBankDialogOpen: true,
            bank: null,
          });
        },
      },
    ];

    return (
      <ApplicationLayout>
        {currentIdentityConfirmation.isProcessing ? (
          <>
            <FormRow size="small">
              <div className="mt-5">
                <IdentityLogo />
              </div>

              <Typography.Header1 className="mt-2">Jūsų tapatybė tvirtinama</Typography.Header1>

              <Typography.Text greyDark justify weight={500}>
                Bankas šiuo metu tvirtina Jūsų tapatybę. Procesas gali trukti iki 1 valandos. Procesui pasibaigus
                gausite patvirtinimo pranešimą el. paštu ir sms žinute .
              </Typography.Text>
            </FormRow>
          </>
        ) : (
          <>
            <Script url="https://psd2.neopay.lt/widget.js" />
            <RequestStepperHeader
              step={currentStep}
              totalSteps={totalSteps}
              title={
                currentIdentityConfirmation.partner
                  ? "Sutuoktinio tapatybės patvirtinimas"
                  : "Patvirtinkite savo tapatybę"
              }
            />

            <FormRow>
              <Typography.Text weight={500} justify grey>
                {currentIdentityConfirmation.partner ? (
                  <>
                    Tam, kad mūsų partneriai galėtų pateikti geriausią pasiūlymą,{" "}
                    <Hidden xs sm>
                      <br />
                    </Hidden>
                    prašome sutuoktinį patvirtinti savo tapatybę.
                  </>
                ) : (
                  <>
                    Tam, kad mūsų partneriai galėtų pateikti geriausią asmeninį{" "}
                    <Hidden xs sm>
                      <br />
                    </Hidden>
                    pasiūlymą, prašome patvirtinti savo tapatybę.
                  </>
                )}
              </Typography.Text>

              {currentIdentityConfirmation.error && (
                <Alert danger className="mt-5">
                  Kažkas negerai. Pabandykite patvirtinti tapatybę dar kartą.
                </Alert>
              )}

              <PaddingWrapper>
                <IdentityConfirmationMethods confirmationMethods={confirmationMethods} />
              </PaddingWrapper>

              <SmartidIdentityDialog
                isOpen={this.state.isSmartidIdentityDialogOpen}
                onClose={() => {
                  this.setState({ isSmartidIdentityDialogOpen: false });
                }}
                requestConfirmationId={currentIdentityConfirmation._id}
              />

              <Dialog
                isOpen={this.state.isBankDialogOpen}
                title="Reikalingas asmens kodas"
                onClose={() => this.setState({ isBankDialogOpen: false })}
              >
                <Typography.Text grey>
                  Darant pavedimą, būtina pažymėti, kad sutinkate pasidalinti asmens kodu su creditking.lt. Nepateikus
                  asmens kodo, tapatybė nebus patvirtinta.
                </Typography.Text>

                <PaddingWrapper size="tiny">
                  <Button
                    fullWidth
                    onClick={() => {
                      if (this.state.bank) {
                        return this.handleNeopay(currentIdentityConfirmation._id);
                      }

                      // paysera
                      window.location.href = currentIdentityConfirmation.href;
                      this.setState({ isLoading: true });
                    }}
                  >
                    Patvirtinti tapatybę
                  </Button>

                  <Button link fullWidth onClick={() => this.setState({ isBankDialogOpen: false })} className="mt-2">
                    Atšaukti
                  </Button>
                </PaddingWrapper>
              </Dialog>
            </FormRow>
          </>
        )}
      </ApplicationLayout>
    );
  }
}

export default IdentityConfirmationPage;
