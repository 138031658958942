import styled from "styled-components";

const Separator = styled.hr`
  /* reset */
  border: 0;
  margin: 0;

  border-bottom: 1px solid #dadbe0;
`;

export default Separator;
