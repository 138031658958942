import React from "react";
import Router from "./Router";
import "./assets/css/Utilities.css";
import { ThemeProvider } from "styled-components";
import theme from "./config/theme";
import GlobalStyles from "./config/GlobalStyles";
import { UserContextProvider } from "./context/UserContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Analytics from "./Analytics";

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <UserContextProvider>
          <div id="App">
            <Analytics />
            <Router />
          </div>
        </UserContextProvider>

        <ToastContainer
          position="bottom-center"
          hideProgressBar={true}
          autoClose={5000}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
        />
      </ThemeProvider>
    );
  }
}

export default App;
