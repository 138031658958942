import React from "react";
import styled, { css } from "styled-components";

const RadioInputWrapper = styled.div`
  background: #f8f8f8;
  border-radius: 8px;

  display: inline-block;
  /* margin-right: 20px; */
  border: 1px solid transparent;

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          display: block;
          width: 100%;
          margin-bottom: 12px;
          padding: 20px 0px;
        `
      : css`
          margin-right: 20px;
          padding: 20px 25px;
        `}

  ${({ checked }) =>
    checked &&
    css`
      border: 1px solid #94b1ff;
    `}
`;

const Label = styled.label`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      margin-left: 25px;
      display: block;
      width: calc(100% - 25px);
    `}
`;

const Input = styled.input`
  margin-right: 5px;
  margin-left: 0px;

  width: 15px;
  height: 15px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
`;

const RadioInput = (props) => (
  <RadioInputWrapper
    checked={props.checked}
    fullWidth={props.fullWidth}
    onClick={props.onClick}
  >
    <Label fullWidth={props.fullWidth}>
      <Input {...props} />
      <span className="pl-1">{props.label}</span>
    </Label>
  </RadioInputWrapper>
);

export default RadioInput;
