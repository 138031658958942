import styled from "styled-components";
import paddingSwitcher from "../switchers/paddingSwitcher";

const PaddingWrapper = styled.div`
  ${({ margin }) => paddingSwitcher(margin)}

  border-bottom: 1px solid transparent;
`;

export default PaddingWrapper;
