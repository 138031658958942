const colors = {
  white: "#FFFFFF",
  black: "#222222",
  grey: "#AAACB6",
  greyDark: "#808188",
  greyLight: "#DADBE0",
  greyLighter: "#F8F8F8",
  blue: "#4575F2",
  blueTone: "#586CDB",
  blueLight: "#94B1FF",
  blueLighter: "#F3F5FE",
  blueLightest: "#F9FAFF",
};

export default colors;
